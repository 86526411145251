import { NotificationInfoT } from '../../types';

const disabledSignService: NotificationInfoT = {
    subTitle: 'Система',
    title: 'Сервис подписи платежей недоступен',
    important: true,
    description: 'Обратитесь в тех. поддержку',
};

export default disabledSignService;
