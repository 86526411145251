import shortText from '@functions/shortText';

import { NotificationInfoT } from '../../types';

const checkTask: NotificationInfoT = {
    subTitle: 'Задачи',
    title() {
        const { notification } = this.props;

        return `Задача №${notification.taskNumber} на проверке`;
    },
    description() {
        const { notification } = this.props;

        return `Результат: ${shortText({ str: notification.taskDescription!, stop: 20, type: 'word' })}`;
    },
    buttons() {
        const { notification } = this.props;

        return [
            {
                className: '_grey',
                content: 'Подробнее',
                pageName: 'tasks-inner',
                pageIds: { 2: notification.taskId! },
            },
        ];
    },
};

export default checkTask;
