import AlertI from '../../types';

const deleteDeal = {
    title: 'Подтвердите удаление сделки',
    description(this: AlertI) {
        const { info } = this.state;

        return `Данное действие нельзя отменить. Вы уверены, что хотите удалить <b>сделку&nbsp;${info}</b>?`;
    },
    buttonText: 'Удалить сделку',
} as const;

export default deleteDeal;
