import { IconT } from '@components/icon/types';
import { PageNamesT } from '@redux/pages';

type NavItemT = {
    pageName: PageNamesT['crm'];
    icon: IconT;
    list?: PageNamesT['crm'][];
};

const nav = {
    verification: {
        pageName: 'settings-docs',
        icon: 'sideBar-settings',
    },
    corporations: {
        pageName: 'corporations',
        icon: 'sideBar-corporations',
    },
    content: {
        pageName: 'content',
        icon: 'sideBar-content',
        list: [
            'content-blog',
            'content-announce',
            'content-legal',
            'content-users-knowledge',
            'content-clients-knowledge',
        ],
    },
    chats: {
        pageName: 'chat',
        icon: 'sideBar-chat',
        list: ['chat', 'chat-templates', 'mailings'],
    },
    joins: {
        pageName: 'joins',
        icon: 'sideBar-joins',
        list: [
            'joins-invites',
            'joins-scripts',
            'joins-templates',
            'joins-contracts',
            'joins-docs',
            'joins-mvd',
        ],
    },
    pays: {
        pageName: 'pays',
        icon: 'sideBar-pays',
        list: ['pays-main', 'pays-groups', 'pays-tax', 'pays-limits', 'pays-projects'],
    },
    recruit: {
        pageName: 'recruit',
        icon: 'sideBar-recruit',
        list: ['recruit-dealGroups', 'recruit-deals', 'recruit-calls'],
    },
    tasks: {
        pageName: 'tasks',
        icon: 'sideBar-tasks',
    },
    manual: {
        pageName: 'manual',
        icon: 'sideBar-manual',
        list: [
            'manual-executors',
            'manual-tags',
            'manual-docs',
            'manual-cities',
            'manual-countries',
            'manual-executorTypes',
        ],
    },
} as const;

export default nav;

export type { NavItemT };
