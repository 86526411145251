import React from 'react';

import Icon from '@components/icon/Icon.tsx';
import Input from '@components/input/Input.tsx';
import setSpacesInText from '@functions/setSpacesInText.ts';

import AlertI, { ContentT } from '../types.ts';

const renderContent: AlertI['renderContent'] = function () {
    const { type, textMessage = '' } = this.state;
    const content = type ? (this.contents[type] as ContentT) : null;
    const title = typeof content?.title === 'function' ? content?.title.call(this) : content?.title;
    const description =
        typeof content?.description === 'function'
            ? content?.description.call(this)
            : content?.description;
    let icon = content?.icon || 'popup-alert';

    if (content?.mode === 'support') {
        icon = 'info';
    }

    return (
        <>
            <div
                className={`v2popup__content _padding _COL _CENTER _NOSCROLL _${type} ${content?.mode ? `_mode-${content.mode}` : ''}`}
            >
                {!content?.textMessage && content && (
                    <i
                        className={`v2popup__icon ${['important', 'denied'].includes(content.mode!) ? '_important' : ''}`}
                    >
                        <Icon name={icon} />
                    </i>
                )}

                <div
                    className="v2popup__title _TEXT _CENTER"
                    dangerouslySetInnerHTML={{ __html: title || '' }}
                ></div>
                <div
                    className="v2popup__description _TEXT _CENTER"
                    dangerouslySetInnerHTML={{
                        __html: description ? setSpacesInText(description) : '',
                    }}
                ></div>
                {content?.textMessage && (
                    <>
                        <div className="v2popup__textMessage">
                            <Input
                                name="textMessage"
                                value={textMessage}
                                onChange={this.textMessageHandler.bind(this)}
                                support={content.textMessage.support}
                                textarea={true}
                            />
                        </div>
                    </>
                )}
                {this.renderFoot()}
            </div>
        </>
    );
};

export default renderContent;
