import I from '../types.ts';

const socketHandler: I['socketHandler'] = function ({
    detail: {
        name,
        data: { type },
    },
}) {
    if (name === 'chat' && ['newMessages', 'setRead'].includes(type)) {
        this.setCounterStack({ name: 'chats', itemName: 'chat' });
    }

    if (name === 'join') {
        this.setCounterStack({ name: 'joins', itemName: 'joins-invites' });
    }

    if (name === 'joinContracts') {
        this.setCounterStack({ name: 'joins', itemName: 'joins-contracts' });
    }

    if (name === 'pays') {
        this.setCounterStack({ name: 'pays', itemName: 'pays-main' });
    }

    if (name === 'tasks') {
        this.setCounterStack({ name: 'tasks', itemName: 'tasks' });
    }

    if (name === 'dealLogs') {
        this.setCounterStack({ name: 'recruit-deals', itemName: 'recruit-deals' });
    }
};

export default socketHandler;
