import alertTask from './notifications/alertTask';
import chatResponsible from './notifications/chatResponsible';
import checkTask from './notifications/checkTask';
import closeAvitoBalance from './notifications/closeAvitoBalance';
import disabledSignService from './notifications/disabledSignService';
import executorJoin from './notifications/executorJoin';
import incomingBeelineCall from './notifications/incomingBeelineCall';
import newDeal from './notifications/newDeal';
import newMessage from './notifications/newMessage';
import newTask from './notifications/newTask';
import rejectTask from './notifications/rejectTask';

const notifications = {
    executorJoin,
    newMessage,
    chatResponsible,
    newTask,
    alertTask,
    checkTask,
    rejectTask,
    newDeal,
    disabledSignService,
    closeAvitoBalance,
    incomingBeelineCall,
} as const;

export default notifications;
