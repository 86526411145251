const integrationUtms = {
    colsOrder: ['description', 'content'],
    cols: {
        description: {
            content: 'Примечание',
            width: 50,
        },
        content: {
            content: 'Значение',
            width: 50,
        },
    },
    deletesCols: [],
    url: 'integrationUtm',
    emptyInfo: {
        title: 'UTM-меток пока нет',
        description: 'Вы можете добавить UTM-метку по кнопке справа',
    },
} as const;

export default integrationUtms;
