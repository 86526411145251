import getPage from '@functions/getPage.ts';
import { StoreT } from '@global/types.ts';

import I from '../types.ts';

import { NavItemT } from '../static/nav.ts';

const getNav: I['getNav'] = function (forList) {
    const { user } = this.state;

    let nav: ReturnType<I['getNav']> = (Object.keys(this.nav) as (keyof typeof this.nav)[])
        .filter((name) => {
            const item = this.nav[name];

            const page = getPage({ name: item.pageName });

            return !page.getCond || page.getCond({ user } as StoreT<'crm'>).condition;
        })
        .map((name) => ({ name }));

    const corporation = this.getCorporation();

    nav = nav.filter(
        (item) => item.name !== 'verification' || corporation?.verificationStatus !== 'active',
    );

    if (forList === true) {
        nav = nav.filter(({ name }) => {
            const item = this.nav[name] as NavItemT;

            return (
                item.list &&
                item.list.find((listItem) => {
                    const page = getPage({ name: listItem });

                    return !page.getCond || page.getCond({ user } as StoreT<'crm'>).condition;
                })
            );
        });
    }

    if (process.env.REACT_APP_ENV === 'prod' && 0) {
        nav = nav.filter((item) => !['recruit', 'tasks'].includes(item.name));
    }

    return nav;
};

export default getNav;
