const integrations = {
    colsOrder: ['name', 'token', 'description'],
    cols: {
        name: {
            content: 'Название',
            width: 20,
        },
        token: {
            content: 'Токен',
            width: 50,
        },
        description: {
            content: 'Примечание',
            width: 30,
        },
    },
    deletesCols: [],
    url: 'integration',
    emptyInfo: {
        title: 'Интеграций пока нет',
        description: 'Вы можете добавить интеграцию по кнопке справа',
    },
} as const;

export default integrations;
