import React from 'react';

import Icon from '@components/icon/Icon.tsx';
import { setCursorSortes } from '@functions/sortesHandler.ts';
import { setCursorSupport } from '@functions/supportHandler.ts';

import SortButtonI from './types.ts';

class SortButton
    extends React.Component<SortButtonI['props'], SortButtonI['state']>
    implements SortButtonI
{
    parent: SortButtonI['parent'];

    constructor(props: SortButtonI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const {
            name,
            items = [],
            currentName,
            defaultName,
            currentState,
            defaultState,
            otherScrollClasses,
        } = this.props;
        const supportProps = setCursorSupport(
            {
                _id: `sort-${name}`,
                content: 'Сортировка',
                dir: 'bottom',
            },
            { targetClassName: '.sortButton' },
        );
        const sortesProps = setCursorSortes(
            {
                _id: `sort-${name}`,
                name,
                dir: 'bottom',
                items,
                currentName: currentName || defaultName,
                currentState: currentState || defaultState,
                defaultName,
                defaultState,
            },
            { targetClassName: '.sortButton', otherScrollClasses },
        );

        return (
            <div
                ref={this.parent}
                className={`sortButton _COL _CLICK ${currentName ? '_active' : ''}`}
                {...supportProps}
                {...sortesProps}
            >
                <i className="sortButton__icon">
                    <Icon name="sort" />
                </i>
            </div>
        );
    }
}

export default SortButton;
