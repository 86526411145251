import React from 'react';

import LoaderBlock from '@components/loaderBlock/LoaderBlock.tsx';
import { CustomListenerT } from '@global/types.ts';

import audioEventHandler from './methods/audioEventHandler.ts';
import clearAudio from './methods/clearAudio.ts';
import getCurrentDuration from './methods/getCurrentDuration.ts';
import getSizeText from './methods/getSizeText.ts';
import initAudio from './methods/initAudio.ts';
import playHandler from './methods/playHandler.ts';
import setProgress from './methods/setProgress.ts';
import setProgressLine from './methods/setProgressLine.ts';

import ChatFileI from './types.ts';

import renderAudio from './renders/renderAudio.tsx';
import renderOther from './renders/renderOther.tsx';

class ChatFile
    extends React.Component<ChatFileI['props'], ChatFileI['state']>
    implements ChatFileI
{
    parent: ChatFileI['parent'];
    audio: ChatFileI['audio'];

    constructor(props: ChatFileI['props']) {
        super(props);
        this.state = {};

        this.audioEventHandler = this.audioEventHandler.bind(this);

        this.parent = React.createRef();
        this.audio = React.createRef();
    }

    getSizeText = getSizeText;
    audioEventHandler = audioEventHandler;

    getCurrentDuration = getCurrentDuration;
    playHandler = playHandler;
    initAudio = initAudio;
    clearAudio = clearAudio;
    setProgressLine = setProgressLine;
    setProgress = setProgress;

    renderAudio = renderAudio;
    renderOther = renderOther;

    componentDidMount(): void {
        (document.addEventListener as CustomListenerT)('audioEvent', this.audioEventHandler);
    }

    componentWillUnmount(): void {
        (document.removeEventListener as CustomListenerT)('audioEvent', this.audioEventHandler);
    }

    render() {
        const { file, className = '', loading } = this.props;

        return (
            <div ref={this.parent} className={`chatFile _ROW ${className}`} data-id={file._id}>
                {typeof loading === 'boolean' && (
                    <LoaderBlock
                        className="chatFile__loader"
                        isShow={loading}
                        loaderClassName="_main"
                    />
                )}
                {typeof file.duration === 'number' && this.renderAudio()}
                {typeof file.duration !== 'number' && this.renderOther()}
            </div>
        );
    }
}

export default ChatFile;
