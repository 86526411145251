import { throttle } from 'throttle-debounce';

import React from 'react';
import { connect } from 'react-redux';

import Animate from '@components/animate/Animate.tsx';
import getCurrentPage from '@functions/getCurrentPage.ts';
import getPage from '@functions/getPage.ts';
import { CustomListenerT, StoreT } from '@global/types.ts';

import checkHide from './methods/checkHide.ts';
import getCounters from './methods/getCounters.ts';
import hideStateHandler from './methods/hideStateHandler.ts';
import playMusic from './methods/playMusic.ts';
import setNotificationsQuery from './methods/setNotificationsQuery.ts';
import socketHandler from './methods/socketHandler.ts';

import NotificationWrapperI from './types.ts';

import NotificationBar from '../notificationBar/NotificationBar.tsx';
import NotificationCount from '../notificationCount/NotificationCount.tsx';

class NotificationWrapper
    extends React.Component<NotificationWrapperI['props'], NotificationWrapperI['state']>
    implements NotificationWrapperI
{
    counterThrottle: NotificationWrapperI['counterThrottle'];

    constructor(props: NotificationWrapperI['props']) {
        super(props);
        this.state = {
            notificationsQuery: [],
            notificationsCounter: 0,
            announcesCounter: 0,
        };

        this.checkHide = this.checkHide.bind(this);
        this.socketHandler = this.socketHandler.bind(this);
    }

    notificationsTimers = {};

    setNotificationsQuery = setNotificationsQuery;
    getCounters = getCounters;
    playMusic = playMusic;
    checkHide = checkHide;
    hideStateHandler = hideStateHandler;

    socketHandler = socketHandler;

    componentDidMount(): void {
        this.checkHide();
        this.getCounters();

        this.counterThrottle = throttle(3_000, this.getCounters.bind(this));

        (document.addEventListener as CustomListenerT)('getSocketData', this.socketHandler);
        document.addEventListener('changeWidthWindow', this.checkHide);
    }

    componentWillUnmount(): void {
        (document.removeEventListener as CustomListenerT)('getSocketData', this.socketHandler);
        document.removeEventListener('changeWidthWindow', this.checkHide);

        (
            Object.keys(this.notificationsTimers) as (keyof typeof this.notificationsTimers)[]
        ).forEach((key) => {
            clearTimeout(this.notificationsTimers[key]);
        });
    }

    render() {
        const { notificationsCounter, announcesCounter, showHideBar } = this.state;
        const { user, storePages, isHideNotificationsBar } = this.props;
        const currentPageName = getCurrentPage({
            storePages,
            filter: (page) => !page.level,
        })!;
        const currentPage = getPage({ name: currentPageName });

        return (
            <>
                {isHideNotificationsBar ? (
                    <>
                        <Animate
                            className="body__notificationsBar _showHideState"
                            isShow={currentPage?.group === 'cabinet' && !!showHideBar}
                        >
                            <NotificationBar
                                setNotificationsQuery={this.setNotificationsQuery.bind(this)}
                                notificationsCounter={notificationsCounter}
                                announcesCounter={announcesCounter}
                            />
                        </Animate>
                        <Animate
                            className="body__notificationsBar _hide"
                            isShow={currentPage?.group === 'cabinet' && !!isHideNotificationsBar}
                        >
                            <NotificationCount
                                handler={this.hideStateHandler.bind(this)}
                                counter={notificationsCounter + announcesCounter}
                            />
                        </Animate>
                    </>
                ) : (
                    <>
                        <Animate
                            className="body__notificationsBar"
                            isShow={
                                currentPage?.group === 'cabinet' &&
                                !!user &&
                                user.corporations.length > 0
                            }
                        >
                            <NotificationBar
                                setNotificationsQuery={this.setNotificationsQuery.bind(this)}
                                notificationsCounter={notificationsCounter}
                                announcesCounter={announcesCounter}
                            />
                        </Animate>
                    </>
                )}
            </>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        user: state.user,
        storePages: state.pages,
        isHideAudioNotification: state.isHideAudioNotification,
        isHideNotificationsBar: state.isHideNotificationsBar,
    };
}

export default connect(mapStateToProps)(NotificationWrapper);
