import React from 'react';

import SortButton from '@components/sortButton/SortButton.tsx';

import WidgetI from '../../types.ts';

const renderSort: WidgetI['renderHeadAction'] = function ({ item }) {
    return (
        <div className="v2widget__headAction">
            <SortButton
                name={item.sortName!}
                items={item.sortActions!}
                currentName={item.sortCurrentName}
                currentState={item.sortCurrentState}
                defaultName={item.sortDefaultName}
                defaultState={item.sortDefaultState}
            />
        </div>
    );
};

export default renderSort;
