import LazyPageI from '@classes/lazyPage/types.ts';

const loadCrmRecruit: LazyPageI['loadComponent'] = async function (name) {
    let Component;

    if (name === 'recruit-dealGroups') {
        Component = (await import('../../../../views/crm/recruit/components/dealGroups/DealGroups'))
            .default;
    }

    if (name === 'recruit-deals') {
        Component = (await import('../../../../views/crm/recruit/components/deals/Deals')).default;
    }

    if (name === 'recruit-deals-group-main') {
        Component = (
            await import('../../../../views/crm/recruit/components/deals/components/main/Main')
        ).default;
    }

    if (name === 'recruit-deals-group-inner') {
        Component = (
            await import('../../../../views/crm/recruit/components/deals/components/inner/Inner')
        ).default;
    }

    if (name === 'recruit-deals-group-inner-main') {
        Component = (
            await import(
                '../../../../views/crm/recruit/components/deals/components/inner/pages/index/Index'
            )
        ).default;
    }

    if (name === 'recruit-deals-group-inner-chat') {
        Component = (
            await import(
                '../../../../views/crm/recruit/components/deals/components/inner/pages/chat/Chat'
            )
        ).default;
    }

    if (name === 'recruit-calls') {
        Component = (await import('../../../../views/crm/recruit/components/calls/Calls')).default;
    }

    return Component;
};

export default loadCrmRecruit;
