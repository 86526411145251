import React from 'react';

import getFormatedDate from '@functions/getFormatedDate.ts';
import getUserName from '@functions/getUserName.ts';
import CallT from '@global/models/Call.ts';

import { RenderColsT } from '../../types.ts';

const renderListCalls: RenderColsT<'listCalls'> = function ({ name: colName, item }) {
    let content;
    let className;

    const call = item as Pick<
        CallT,
        '_id' | 'type' | 'executorPhone' | 'user' | 'typeText' | 'createdDate' | 'number'
    >;

    if (colName === 'name') {
        const inUser =
            call.type === 'incoming'
                ? `<span class="_noWrap">${call.executorPhone}</span>`
                : getUserName({ user: call.user, type: 'short' });
        const outUser =
            call.type === 'outcoming'
                ? `<span class="_noWrap">${call.executorPhone}</span>`
                : getUserName({ user: call.user, type: 'short' });

        content = (
            <>
                <div className="v2table__text">
                    <b>
                        {call.typeText} звонок №{call.number}
                    </b>{' '}
                    {getFormatedDate({
                        date: new Date(call.createdDate),
                        type: 'full',
                        isShortYear: true,
                    })}
                    ,{' '}
                    <div
                        className="v2table__text _inline"
                        dangerouslySetInnerHTML={{ __html: `от: ${inUser}, кому: ${outUser}` }}
                    ></div>
                </div>
            </>
        );
    }

    return { content, className };
};

export default renderListCalls;
