import { d, l, s, w } from '@functions/seo';
import { StoreT, SystemT } from '@global/types';

import pages from './pages.ts';

type SettingsT<T extends SystemT> = {
    type: keyof StoreT<T> | '';
    data?: unknown;
    resolve?: () => void;
} & Record<any, unknown>;

type DispatcherT = <T extends SystemT>(settings: SettingsT<T>) => Promise<void>;

function updateData<T extends SystemT>(settings: SettingsT<T>, state: StoreT<T>): any {
    let newData;

    if (settings.data === null || settings.data === undefined) {
        newData = null;
    } else if (
        typeof settings.data === 'number' ||
        typeof settings.data === 'string' ||
        typeof settings.data === 'boolean'
    ) {
        newData = settings.data;
    } else if (Array.isArray(settings.data)) {
        newData = settings.data;
    } else {
        newData = {
            ...(settings.type ? { ...(state[settings.type] as { [s: string]: any }) } : {}),
            ...settings.data,
        };
    }

    return newData;
}

function getReducer<T extends SystemT>(defaultState: StoreT<T>) {
    return (state: StoreT<T> = defaultState, settings: any): any => {
        switch (settings.type) {
            case 'socket':
                return {
                    ...state,
                    ...{
                        socket: settings.data,
                    },
                };
            case 'user':
                if (settings.resolve) {
                    settings.resolve();
                }

                if (settings.data) {
                    localStorage.setItem('user', JSON.stringify(settings.data));
                } else {
                    localStorage.removeItem('user');
                }

                return {
                    ...state,
                    ...{
                        user: settings.data,
                    },
                };
            default: {
                if (settings.type) {
                    const newData = updateData(settings, state);

                    if (settings.resolve && typeof settings.resolve === 'function') {
                        settings.resolve();
                    }

                    return {
                        ...state,
                        ...{
                            [settings.type]: newData,
                        },
                    };
                }

                const data: { [s: string]: any } = {};

                Object.keys(settings).forEach((key) => {
                    if (key !== 'type') {
                        data[key] = (settings as { [s: string]: any })[key];
                    }
                });

                const newData = updateData({ type: settings.type, data }, state);

                if (settings.resolve && typeof settings.resolve === 'function') {
                    settings.resolve();
                }

                return {
                    ...state,
                    ...newData,
                };
            }
        }
    };
}

type DefaultPropsT = {
    currentPages?: readonly string[];
    seoData?: StoreT['seoData'];
} & Record<any, unknown>;

function getDefaultState(props: DefaultPropsT): StoreT {
    const resultPages = {} as StoreT['pages'];

    if (props.currentPages) {
        pages.forEach((page) => {
            resultPages[page.name] = {
                isShow: props.currentPages!.includes(page.name),
            };
        });
    }

    let theme = (l.getItem('theme') ? l.getItem('theme') : 'light') as StoreT['theme'];

    if (!['dark', 'light'].includes(theme!)) {
        theme = 'light';

        l.setItem('theme', theme);
    }

    return {
        seoMode: s(),
        rootInit: s(),
        pagesIds: {},
        serverData: {},
        levels: [],
        device: 'desktop',
        pages: resultPages,
        windowWidth: d.documentElement.clientWidth,
        windowHeight: w.innerHeight,
        theme: theme as StoreT['theme'],
        filterPopup: { isShow: false },
        alertPopup: { isShow: false },
        calendarPopup: { isShow: false },
        cursorSupport: [],
        cursorActions: [],
        cursorList: [],
        cursorEmodziList: [],
        cursorSortes: [],
        seoData: props.seoData,
    };
}

export { updateData, getReducer, getDefaultState };

export type { SettingsT, DispatcherT, DefaultPropsT };
