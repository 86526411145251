import React from 'react';
import { connect } from 'react-redux';

import CustomHead from '@components/CustomHead.jsx';
import Pages from '@components/pages/Pages.tsx';
import checkHasCorporation from '@functions/crm/checkHasCorporation.js';
import { s } from '@functions/seo.ts';
import { CustomListenerT, StoreT } from '@global/types.ts';

import socketHandler from './methods/socketHandler.ts';
import visibilityDocChange from './methods/visibilityDocChange.ts';

import AppI from './types.ts';

import renderNewYear from './renders/renderNewYear.tsx';
import renderNotificationbar from './renders/renderNotificationbar.tsx';
import renderOther from './renders/renderOther.tsx';
import renderPopups from './renders/renderPopups.tsx';
import renderSidebar from './renders/renderSidebar.tsx';
import pages from './static/pages.tsx';

const Styles = !s() && require('./components/styles/Styles.tsx').default;

class App extends React.Component<AppI['props'], AppI['state']> implements AppI {
    parent: AppI['parent'];

    constructor(props: AppI['props']) {
        super(props);
        this.state = {
            loadPages: {},
        };

        this.socketHandler = this.socketHandler.bind(this);
        this.visibilityDocChange = this.visibilityDocChange.bind(this);

        this.parent = React.createRef();
    }

    pages = pages;

    socketHandler = socketHandler;
    visibilityDocChange = visibilityDocChange;

    renderNewYear = renderNewYear;
    renderPopups = renderPopups;
    renderSidebar = renderSidebar;
    renderNotificationbar = renderNotificationbar;
    renderOther = renderOther;

    componentDidMount() {
        (document.addEventListener as CustomListenerT)('getSocketData', this.socketHandler);
        (document.addEventListener as CustomListenerT)(
            'visibilityDocChange',
            this.visibilityDocChange,
        );

        document.dispatchEvent(new CustomEvent('click'));
    }

    componentWillUnmount() {
        (document.removeEventListener as CustomListenerT)('getSocketData', this.socketHandler);
        (document.removeEventListener as CustomListenerT)(
            'visibilityDocChange',
            this.visibilityDocChange,
        );
    }

    render() {
        const { user, isHideNotificationsBar } = this.props;
        const pagesClasses = [
            'JSTransVertical body__page _ITEM _COL _crm',
            ...(user && !checkHasCorporation({ user }) ? ['_waitCorporation'] : []),
            ...(isHideNotificationsBar ? ['_hideNotificationsBar'] : []),
        ];

        return (
            <>
                <CustomHead title="CRM LIVECARGO" description="CRM LIVECARGO">
                    {!s() && <link rel="icon" href={require('@img/favicon.ico')} />}
                    <meta name="yandex-verification" content="0dcdf70d2b548de3" />
                </CustomHead>
                {Styles && <Styles />}

                {!s() && (
                    <>
                        {this.renderNewYear()}
                        {this.renderPopups()}
                        {this.renderSidebar()}
                        {this.renderNotificationbar()}
                        {this.renderOther()}
                    </>
                )}

                <Pages
                    className="body__innerPages _ITEMS"
                    classNamePage={pagesClasses.join(' ')}
                    filter={(page) =>
                        page.level === 1 ||
                        [
                            'profile',
                            'auth',
                            'content-infoPublic',
                            'pep',
                            'pdf',
                            'recruit-dealGroup',
                            'clients-knowledge',
                            'users-knowledge',
                        ].includes(page.name) ||
                        page.group === 'public'
                    }
                    pages={this.pages}
                    context={this}
                    fullHeight={true}
                />
            </>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        user: state.user,
        storePages: state.pages,
        isHideNotificationsBar: state.isHideNotificationsBar,
        logsPopup: state.logsPopup,
        chatTemplatesPopup: state.chatTemplatesPopup,
        paySignPopup: state.paySignPopup,
        contractSignPopup: state.contractSignPopup,
        payPopup: state.payPopup,
        joinContractPopup: state.joinContractPopup,
        payGroupUpload: state.payGroupUpload,
        userPopup: state.userPopup,
        corporationEditPopup: state.corporationEditPopup,
        corporationAmountDocPopup: state.corporationAmountDocPopup,
        corporationCreatePopup: state.corporationCreatePopup,
        roleSectionPopup: state.roleSectionPopup,
        rolePopup: state.rolePopup,
        docPopup: state.docPopup,
        walletPopup: state.walletPopup,
        executorCreatePopup: state.executorCreatePopup,
        executorMvdPopup: state.executorMvdPopup,
        executorLimitsPopup: state.executorLimitsPopup,
        chatEditPopup: state.chatEditPopup,
        galeryPopup: state.galeryPopup,
        galeryPin: state.galeryPin,
        newYearPopup: state.newYearPopup,
        designMode: state.designMode,
        newYearDate: state.newYearDate,
        chatTemplatePopup: state.chatTemplatePopup,
        chatMailingPopup: state.chatMailingPopup,
        exportPopup: state.exportPopup,
        chatExecutorDocPopup: state.chatExecutorDocPopup,
        payGroupPopup: state.payGroupPopup,
        integrationPopup: state.integrationPopup,
        beelineUserPopup: state.beelineUserPopup,
        dealGroupPopup: state.dealGroupPopup,
        dealTriggerPopup: state.dealTriggerPopup,
        taskPopup: state.taskPopup,
        legalNavPopup: state.legalNavPopup,
        callPopup: state.callPopup,
        newVersionPopup: state.newVersionPopup,
        dealPopup: state.dealPopup,
        dealGroupCustomFieldsPopup: state.dealGroupCustomFieldsPopup,
        knowledgeSettingsPopup: state.knowledgeSettingsPopup,
        chatsPopup: state.chatsPopup,
        dealCallPopup: state.dealCallPopup,
        dealsImportPopup: state.dealsImportPopup,
        newChatPopup: state.newChatPopup,
        integrationUtmPopup: state.integrationUtmPopup,
    };
}

export default connect(mapStateToProps)(App);
