import I from '../types.ts';

const checkChange: I['checkChange'] = function (force) {
    const { renderKey, updateKey, forceRenderKey, disabled, windowIsReady } = this.props;

    if (disabled) {
        return;
    }

    if (
        renderKey !== this.renderKey ||
        updateKey !== this.updateKey ||
        forceRenderKey !== this.forceRenderKey ||
        this.windowIsReady !== windowIsReady ||
        force
    ) {
        this.updateItems({
            isRender:
                renderKey !== this.renderKey ||
                forceRenderKey !== this.forceRenderKey ||
                this.windowIsReady !== windowIsReady,
            isUpdate:
                updateKey !== this.updateKey ||
                forceRenderKey !== this.forceRenderKey ||
                this.windowIsReady !== windowIsReady,
        });

        this.renderKey = renderKey;
        this.updateKey = updateKey;
        this.forceRenderKey = forceRenderKey;
        this.windowIsReady = windowIsReady;
    }
};

export default checkChange;
