import React from 'react';

import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import Icon from '@components/icon/Icon.tsx';
import { setCursorSupport } from '@functions/supportHandler.ts';

import WidgetI from '../../types.ts';

const renderFilter: WidgetI['renderHeadAction'] = function ({ item }) {
    const { actions, name, filterQuery, observerSupportClassName } = this.props;
    const { filterName } = item;
    const action = actions!.find((innerAction) => innerAction.filterName === filterName);
    const filterCallback = action!.filterCallback;

    const counters: string[] = [];

    if (filterQuery) {
        filterQuery.forEach((block) => {
            if (!counters.includes(block.name as string)) {
                counters.push(block.name as string);
            }
        });
    }

    const filterCounter = counters.length;
    const supportProps = setCursorSupport(
        {
            _id: `${name}-${item.name}`,
            content: 'Фильтр',
            dir: 'bottom',
        },
        { targetClassName: '.v2widget__headAction', observerSupportClassName },
    );

    return (
        <div
            className="v2widget__headAction"
            onClick={() => {
                if (filterCallback) {
                    filterCallback();
                } else {
                    document.dispatchEvent(
                        new CustomEvent('showFilter', { detail: { name: filterName } }),
                    );
                }
            }}
            {...supportProps}
        >
            <div className="v2widget__headActionInner _COL _CLICK">
                <AnimateChange
                    className="v2widget__headActionCounter"
                    renderKey={filterCounter > 0 ? filterCounter : undefined}
                    styles={['width']}
                >
                    <>{filterCounter}</>
                </AnimateChange>
                <i className="v2widget__headActionIcon">
                    <Icon name="widget-filter" />
                </i>
            </div>
        </div>
    );
};

export default renderFilter;
