import React from 'react';

import Icon from '@components/icon/Icon.tsx';
import Link from '@components/link/Link.tsx';

import WidgetI from '../types.ts';

const renderBack: WidgetI['renderBack'] = function () {
    const { backPageName } = this.props;

    if (!backPageName) {
        return;
    }

    return (
        <div className="v2widget__headBack">
            <Link className="v2widget__headBackInner _col _click" pageName={backPageName}>
                <div className="v2widget__headBackIcon">
                    <Icon name="arrow-back" />
                </div>
            </Link>
        </div>
    );
};

export default renderBack;
