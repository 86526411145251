import AlertI from '../../types';

const deleteChat = {
    title: 'Подтвердите удаление чата',
    description(this: AlertI) {
        return `Данное действие нельзя отменить. Вы уверены, что хотите удалить чат?`;
    },
    buttonText: 'Удалить чат',
} as const;

export default deleteChat;
