const roleUsersHasProxies = {
    title: 'Внимание!',
    description:
        'Изменение данных прав приведет к расторжению <b>всех доверенностей</b> у сотрудников с этой ролью',
    buttonText: 'Не сохранять',
    cancelText: 'Всё равно сохранить',
    mode: 'important',
} as const;

export default roleUsersHasProxies;
