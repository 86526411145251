import { NotificationInfoT } from '../../types';

const closeAvitoBalance: NotificationInfoT = {
    subTitle: 'Система',
    title: 'Баланс авито заканчивается',
    important: true,
    description: 'Не забудьте пополнить баланс, чтобы интеграция не остановила работу',
};

export default closeAvitoBalance;
