import React from 'react';

import WidgetField from '@components/crm/widgetField/WidgetField';

import AlertI from '../../types';

const deleteDealStep = {
    title: 'Удаление этапа',
    description:
        'На данном этапе есть <b>активные сделки</b>. Выберите из списка ниже этап для переноса сделок',
    buttonText: 'Удалить и перенести',
    renderOther(this: AlertI) {
        const { model, loadingKey, error } = this.state;
        const info = this.state.info as { groupId: string; stepId: string };

        return (
            <>
                <div className="v2popup__field _bottom">
                    <WidgetField
                        model={model}
                        className="_bigSize"
                        name="stepId"
                        prop="stepId"
                        type="select"
                        inputSupport="Этап"
                        onChange={async (resultData) => {
                            await this.change(resultData);
                        }}
                        value={model?.scriptId}
                        iserror={error?.name === 'stepId'}
                        cardName="dealStep"
                        disabled={loadingKey === 'save'}
                        selectList="dealSteps"
                        selectProps={{ name: 'stepName' }}
                        selectText={this.getValue({ key: 'stepName' })?.value || ''}
                        defaultQuery={[
                            { key: 'groupId', value: info.groupId },
                            { key: 'notIds', value: info.stepId },
                        ]}
                    />
                </div>
            </>
        );
    },
} as const;

export default deleteDealStep;
