import React from 'react';

import Avatar from '@components/avatar/Avatar.tsx';
import IntegrationT from '@global/models/Integration.ts';

import { RenderColsT } from '../../types.ts';

const renderListIntegrations: RenderColsT<'listIntegrations'> = function ({ name: colName, item }) {
    const integration = item as Pick<IntegrationT, '_id' | 'title' | 'logoSrc' | 'description'>;
    let content;
    let className;

    if (colName === 'name') {
        className = '_full';
        content = (
            <>
                <div className={`v2table__preview _ROW _hiddenAvatar _mediumSize _notBack`}>
                    <div className="v2table__previewAvatar">
                        <Avatar className="_notRad" image={integration.logoSrc} />
                    </div>
                    <div className="v2table__previewInfo">
                        <div className="v2table__previewInfoInner">
                            <div className="v2table__text">{integration.title}</div>
                            {integration.description && (
                                <div className="v2table__text _grey">
                                    , {integration.description}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return { content, className };
};

export default renderListIntegrations;
