import I from '../types.ts';

const getCounter: I['getCounter'] = function () {
    const { query } = this.props;
    const counters: string[] = [];

    if (query) {
        query.forEach((block) => {
            if (!counters.includes(block.name as string)) {
                counters.push(block.name as string);
            }
        });
    }

    return counters.length;
};

export default getCounter;
