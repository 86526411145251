import React from 'react';

import Media from '@components/media/Media.tsx';
import Popup from '@components/popup/Popup.tsx';

import AlertI from '../types.ts';

const renderDesktop: AlertI['renderDesktop'] = function () {
    return (
        <>
            <Media current="desktop">
                <Popup
                    name="alertPopup"
                    className="_headCenter"
                    close={this.close.bind(this, false)}
                    checkClose={() => !document.querySelector('.v2cursorLists__item')}
                >
                    {this.renderContent()}
                </Popup>
            </Media>
        </>
    );
};

export default renderDesktop;
