import getFormatedDate from '@functions/getFormatedDate';

import AlertI from '../../types';

const contractAlreadyCreate = {
    title: 'Предупреждение',
    description(this: AlertI) {
        const info = this.state.info as {
            fullName: string;
            contract: { number: number; date: string };
            type?: string;
        };

        if (info.type === 'userProxy') {
            return `На сотрудника <b>${info.fullName}</b> есть действующая доверенность <b>№${info.contract.number} от ${getFormatedDate({ date: new Date(info.contract.date) })}</b>. Создание новой приведет к её расторжению`;
        }

        return `На исполнителя <b>${info.fullName}</b> есть действующий договор <b>№${info.contract.number} от ${getFormatedDate({ date: new Date(info.contract.date) })}</b>. Создание нового приведет к его расторжению`;
    },
    buttonText: 'Не создавать',
    cancelText: 'Всё равно создать',
    mode: 'important',
} as const;

export default contractAlreadyCreate;
