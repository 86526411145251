import React from 'react';

import Icon from '@components/icon/Icon.tsx';
import handlerPopup from '@functions/handlerPopup.ts';
import { setCursorSupport } from '@functions/supportHandler.ts';

import WidgetI from '../../types.ts';

const renderExport: WidgetI['renderHeadAction'] = function ({ item, prop }) {
    const { counter, actions, name } = this.props;
    const action = actions?.find((innerAction) => innerAction.name === prop);
    const { exportName } = item;
    const exportQuery = action?.exportQuery || [];
    const supportProps = setCursorSupport(
        {
            _id: `${name}-table-export`,
            content: 'Экспорт',
            dir: 'bottom',
        },
        { targetClassName: '.v2widget__headAction' },
    );

    return (
        <div className="v2widget__headAction">
            <div
                className="v2widget__headActionInner _COL _CLICK"
                {...supportProps}
                onClick={() => {
                    const { filterQuery } = this.props;

                    if (counter && counter > 0) {
                        handlerPopup('exportPopup', {
                            isShow: true,
                            filterQuery: [...(filterQuery || []), ...exportQuery],
                            type: exportName,
                        });
                    }
                }}
            >
                <i className="v2widget__headActionIcon">
                    <Icon name="widget-export" />
                </i>
            </div>
        </div>
    );
};

export default renderExport;
