import { NotificationInfoT } from '../../types';

const incomingBeelineCall: NotificationInfoT = {
    subTitle: 'Сделки',
    title: 'Вам поступает звонок',
    important: true,
    description() {
        const { notification } = this.props;
        const { callPhone } = notification;

        return `С номера телефона <span class="_noWrap"><b>${callPhone}</b></span>`;
    },
};

export default incomingBeelineCall;
