import React from 'react';

import setSpacesInText from '@functions/setSpacesInText.ts';

import { RenderColsT } from '../../types.ts';

const renderListCuperLeads: RenderColsT<'listCuperLeadTypes'> = function ({ name: colName, item }) {
    let content;
    let className;

    const cuperLead = item as {
        _id: string;
        name: string;
        description: string;
    };

    if (colName === 'name') {
        content = (
            <>
                <div className="v2table__text _block">{cuperLead.name}</div>
                <div
                    className="v2table__text _grey"
                    dangerouslySetInnerHTML={{ __html: setSpacesInText(cuperLead.description) }}
                ></div>
            </>
        );
    }

    return { content, className };
};

export default renderListCuperLeads;
