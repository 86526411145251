import LazyPageI from '@classes/lazyPage/types.ts';

const loadCrm: LazyPageI['loadComponent'] = async function (name) {
    let Component;

    if (name === 'corporation-index') {
        Component = (await import('../../../../views/crm/index/Index')).default;
    }

    if (name === 'corporations') {
        Component = (await import('../../../../views/crm/corporations/Corporations')).default;
    }

    if (name === 'auth') {
        Component = (await import('../../../../views/crm/Auth')).default;
    }

    if (name === 'chat') {
        Component = (await import('../../../../views/crm/chat/Chat')).default;
    }

    if (name === 'manual') {
        Component = (await import('../../../../views/crm/manual/Manual')).default;
    }

    if (name === 'content') {
        Component = (await import('../../../../views/crm/content/Content')).default;
    }

    if (name === 'profile') {
        Component = (await import('../../../../views/crm/profile/Profile')).default;
    }

    if (name === 'settings') {
        Component = (await import('../../../../views/crm/settings/Settings')).default;
    }

    if (name === 'joins') {
        Component = (await import('../../../../views/crm/joins/Joins')).default;
    }

    if (name === 'pays') {
        Component = (await import('../../../../views/crm/pays/Pays')).default;
    }

    if (name === 'recruit') {
        Component = (await import('../../../../views/crm/recruit/Recruit')).default;
    }

    if (name === 'tasks') {
        Component = (await import('@components/crm/modelsPage/ModelsPage')).default;
    }

    if (name === 'recruit-dealGroup') {
        Component = (await import('../../../../views/crm/recruit/components/dealGroup/DealGroup'))
            .default;
    }

    if (name === 'chat-templates') {
        Component = (await import('../../../../views/crm/chatTemplates/ChatTemplates')).default;
    }

    if (name === 'mailings') {
        Component = (await import('../../../../views/crm/mailings/Mailings')).default;
    }

    if (name === 'pdf') {
        Component = (await import('../../../../views/crm/Pdf')).default;
    }

    if (name === 'public') {
        Component = (await import('../../../../views/crm/public/Public')).default;
    }

    if (name === 'pep') {
        Component = (await import('../../../../views/crm/Pep')).default;
    }

    if (name === 'users-knowledge') {
        Component = (await import('../../../../views/crm/knowledge/Knowledge')).default;
    }

    if (name === 'clients-knowledge') {
        Component = (await import('../../../../views/crm/knowledge/Knowledge')).default;
    }

    return Component;
};

export default loadCrm;
