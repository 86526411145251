import React from 'react';

import IntegrationUtmT from '@global/models/IntegrationUtm.ts';
import { CursorActionsItemT } from '@global/types.ts';

import More from '../components/More.tsx';

import { RenderColsT } from '../../types.ts';

const renderIntegrationUtms: RenderColsT<'integrationUtms'> = function ({ name: colName, item }) {
    const integrationUtm = item as IntegrationUtmT;
    let content;
    let className;

    if (colName === 'description') {
        content = <>{integrationUtm.description || '–'}</>;
    }

    if (colName === 'content') {
        const { otherData } = this.props;
        const getMoreActions = otherData?.getMoreActions as (data: {
            integrationUtm: IntegrationUtmT;
        }) => CursorActionsItemT[];
        className = '_full _withMore';

        content = (
            <>
                <div className="v2table__text">{integrationUtm.content}</div>
                <More
                    id={`integration-${integrationUtm._id}`}
                    items={getMoreActions({ integrationUtm })}
                />
            </>
        );
    }

    return { content, className };
};

export default renderIntegrationUtms;
