import deleteNotification from '@requests/deleteNotification';

import { NotificationInfoT } from '../../types';

const newDeal: NotificationInfoT = {
    subTitle: 'Сделки',
    title() {
        const { notification } = this.props;

        return `Новая сделка №${notification.dealNumber} для вас`;
    },
    description() {
        const { notification } = this.props;

        return `Исполнитель ${notification.dealDescription}`;
    },
    buttons() {
        const { notification } = this.props;

        return [
            {
                className: '_blue',
                content: 'Взять в работу',
                pageName: 'recruit-deals-group-inner',
                pageIds: { 4: notification.dealId! },
                handler: this.buttonHandler.bind(
                    this,
                    0,
                    deleteNotification.bind(null, { id: notification._id }),
                ),
            },
            {
                className: '_grey',
                content: 'Подробнее',
                pageName: 'recruit-deals-group-inner',
                pageIds: { 4: notification.dealId! },
            },
        ];
    },
};

export default newDeal;
