import React from 'react';

import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import FilterButton from '@components/filterButton/FilterButton.tsx';
import Icon from '@components/icon/Icon.tsx';
import SortButton from '@components/sortButton/SortButton.tsx';
import { dispatcher } from '@redux/redux.ts';

import I from '../types.ts';

const renderActionsHead: I['renderActionsHead'] = function () {
    const { filter = [], filterQuery = [], sortCurrentName, sortCurrentState } = this.state;
    const { isHideAudioNotification, setNotificationsQuery } = this.props;
    // const filterCounter = this.getFilterCounter();
    // const supportProps = setCursorSupport(
    //     {
    //         _id: `notificationsFilter`,
    //         content: 'Фильтр',
    //         dir: 'bottom',
    //     },
    //     { targetClassName: '.v2notificationBar__filterButton', className: '_upper' },
    // );

    return (
        <div className="v2notificationBar__pageHead _ROW">
            <div
                className={`v2notificationBar__notifyButton _CLICK _ROW ${isHideAudioNotification ? '_hide' : ''}`}
                onClick={() => {
                    dispatcher<'crm'>({
                        type: 'isHideAudioNotification',
                        data: !isHideAudioNotification,
                    });

                    if (isHideAudioNotification) {
                        localStorage.removeItem('isHideAudioNotification');
                    } else {
                        localStorage.setItem('isHideAudioNotification', 'true');
                    }
                }}
            >
                <div className="v2notificationBar__notifyButtonIcon">
                    <Icon name="notifications" />
                </div>
                <AnimateChange
                    className="v2notificationBar__notifyButtonContent"
                    renderKey={!!isHideAudioNotification}
                >
                    <>
                        <b>{isHideAudioNotification ? 'Включить' : 'Выключить'}</b> оповещение
                    </>
                </AnimateChange>
            </div>
            <div className="v2notificationBar__filterActions">
                <div className="v2notificationBar__filterAction">
                    <SortButton
                        name="notifications"
                        items={[
                            { text: 'По дате создания', name: 'created' },
                            { text: 'По важности', name: 'important' },
                        ]}
                        currentName={sortCurrentName}
                        currentState={sortCurrentState}
                        otherScrollClasses={['v2notificationBar__pageScroll']}
                    />
                </div>
                <div className="v2notificationBar__filterAction">
                    <FilterButton
                        name="notifications"
                        query={filterQuery}
                        filter={filter}
                        setFilter={(resultQuery) => {
                            setNotificationsQuery(resultQuery);

                            this.setFilter(resultQuery);
                        }}
                        localName={this.getLocalFilterName()}
                    />
                </div>
            </div>
        </div>
    );
};

export default renderActionsHead;
