import React from 'react';

import WidgetField from '@components/crm/widgetField/WidgetField';

import AlertI from '../../types';

const setSuccessDealStep = {
    title: 'Выберите сценарий подключения',
    description:
        'Для переноса сделки в этап <b>«Успешно»</b> необходимо выбрать сценарий, по которому будет создано подключение исполнителя',
    buttonText: 'Выбрать',
    renderOther(this: AlertI) {
        const { model, loadingKey, error } = this.state;
        const info = this.state.info as {
            groupId: string;
            successStepId: string;
        };

        return (
            <>
                <div className="v2popup__field _bottom">
                    <WidgetField
                        model={model}
                        className="_bigSize"
                        name="successScriptId"
                        prop="successScriptId"
                        type="select"
                        inputSupport="Сценарий"
                        onChange={async (resultData) => {
                            await this.change(resultData);
                        }}
                        value={model?.groupId}
                        iserror={error?.name === 'successScriptId'}
                        cardName="successScriptId"
                        disabled={loadingKey === 'save'}
                        selectList="joinScripts"
                        selectProps={{ name: 'successScriptName' }}
                        selectText={this.getValue({ key: 'successScriptName' })?.value || ''}
                        defaultQuery={[
                            { key: 'groupId', value: info.groupId },
                            { key: 'successStepId', value: info.successStepId },
                        ]}
                    />
                </div>
            </>
        );
    },
} as const;

export default setSuccessDealStep;
