import React from 'react';

import Icon from '@components/icon/Icon.tsx';
import Input from '@components/input/Input.tsx';
import { setEmodziList } from '@functions/emodziListHandler.ts';

import I from '../types.ts';

const renderInput: I['renderInput'] = function () {
    const { loadingKey } = this.state;
    const {
        model,
        name,
        prop,
        onChange,
        reg,
        regExp,
        concat,
        disabled,
        max,
        isAmount,
        cardName,
        textarea,
        inputSupport,
        readOnly,
        withEmodzi,
        areaCalcHeight,
        areaMinHeight,
        withPast,
        dateAll,
    } = this.props;

    const value = this.props.value as string;

    const emodziProps =
        !disabled &&
        setEmodziList(
            {
                _id: `widget-field-emodzi-${name}`,
                dir: 'bottom',
                getInput: () =>
                    this.parent.current!.querySelector(
                        `.v2input._name${name} .v2input__field`,
                    ) as HTMLInputElement,
                getValue: function (this: I) {
                    return this.props.value as string;
                }.bind(this),
                onChange: async (thisValue: string) => {
                    await onChange({ [prop]: thisValue, UPDATED_KEY: new Date().getTime() });
                },
            },
            { targetClassName: '.v2widgetField__fieldEmodzi' },
        );

    return (
        <>
            <div className="v2widgetField__field">
                <Input
                    name={name}
                    value={value ?? ''}
                    onChange={async ({ value: resultValue }, e) => {
                        onChange({ [prop]: resultValue });

                        if (e) {
                            this.cityListHandler({ value: resultValue as string, e });
                        }

                        this.asyncInputHandler(resultValue as string);
                    }}
                    support={inputSupport || '–'}
                    reg={reg}
                    regExp={regExp}
                    concat={concat}
                    disabled={disabled || !!loadingKey}
                    readOnly={readOnly}
                    max={max}
                    id={cardName}
                    isAmount={isAmount}
                    updatedKey={model?.UPDATED_KEY}
                    textarea={textarea}
                    calcHeight={areaCalcHeight}
                    minHeight={areaCalcHeight ? () => areaMinHeight || 120 : undefined}
                    // maxHeight={areaCalcHeight ? () => 360 : undefined}
                    className="_widgetField"
                    dateWithPast={withPast}
                    dateAll={dateAll}
                />
                {withEmodzi && (
                    <i className="v2widgetField__fieldEmodzi _CLICK" {...emodziProps}>
                        <Icon name="emodzi" />
                    </i>
                )}
            </div>
        </>
    );
};

export default renderInput;
