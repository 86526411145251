import I from '../types.ts';

const audioEventHandler: I['audioEventHandler'] = async function ({ detail: { id } }) {
    const { file } = this.props;

    if (!file._id || file._id === id) {
        return;
    }

    if (this.state.isPlay) {
        await this.playHandler(false);
    }
};

export default audioEventHandler;
