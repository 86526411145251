import DealTriggerT from '@global/models/DealTrigger';

const icons: Record<DealTriggerT['key'], IconsT> = {
    createTask: 'deal-trigger-task',
    createJoin: 'deal-trigger-join',
    sendSms: 'deal-trigger-sms',
    cuperCandi: 'deal-trigger-lead',
};

export default icons;
