import I from '../types.ts';

const socketHandler: I['socketHandler'] = async function ({
    detail: {
        name,
        data: { action, id, notification },
    },
}) {
    const { notificationsQuery = [] } = this.state;
    const { user } = this.props;

    if (name === 'notifications') {
        if (
            !action &&
            notification &&
            (notification.corporationId === null ||
                notification.corporationId === user?.idOfCurrentCorporation)
        ) {
            let canAdd = true;

            if (notificationsQuery.find((item) => item.name === 'types')) {
                const types = notificationsQuery
                    .filter((item) => item.name === 'types')
                    .map((item) => item.value);

                if (!types.includes(notification!.group)) {
                    canAdd = false;
                }
            }

            if (canAdd) {
                this.playMusic(id);
            }
        }

        if (this.counterThrottle) {
            this.counterThrottle();
        }
    }

    if (name === 'userArticles') {
        if (this.counterThrottle) {
            this.counterThrottle();
        }
    }
};

export default socketHandler;
