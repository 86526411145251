import React from 'react';

import WidgetField from '@components/crm/widgetField/WidgetField';

import AlertI from '../../types';

const executorActive = {
    title: 'Восстановление исполнителя',
    description: `У исполнителя была <b>смена</b> правовой формы. Чтобы восстановить исполнителя, необходимо выбрать <b>новый сценарий</b>, который <b>соответствует новой ОПФ</b> исполнителя.`,
    buttonText: 'Восстановить',
    renderOther(this: AlertI) {
        const { model, loadingKey, error } = this.state;

        return (
            <>
                <div className="v2popup__field _bottom">
                    <WidgetField
                        model={model}
                        className="_bigSize"
                        name="scriptId"
                        prop="scriptId"
                        type="select"
                        inputSupport="Сценарий"
                        onChange={async (resultData) => {
                            await this.change(resultData);
                        }}
                        value={model?.scriptId}
                        iserror={error?.name === 'scriptId'}
                        cardName="executorActive"
                        disabled={loadingKey === 'save'}
                        selectList="joinScripts"
                        selectProps={{ name: 'scriptName' }}
                        selectText={this.getValue({ key: 'scriptName' })?.value || ''}
                    />
                </div>
            </>
        );
    },
} as const;

export default executorActive;
