import shortText from '@functions/shortText';
import setTaskStatus from '@requests/setTaskStatus';

import { NotificationInfoT } from '../../types';

const newTask: NotificationInfoT = {
    subTitle: 'Задачи',
    title() {
        const { notification } = this.props;

        return `Новая задача №${notification.taskNumber} для вас`;
    },
    description() {
        const { notification } = this.props;

        return `Описание: ${shortText({ str: notification.taskDescription!, stop: 20, type: 'word' })}`;
    },
    buttons() {
        const { notification } = this.props;

        return [
            {
                className: '_blue',
                content: 'Взять в работу',
                pageName: 'tasks-inner',
                pageIds: { 2: notification.taskId! },
                handler: this.buttonHandler.bind(
                    this,
                    0,
                    setTaskStatus.bind(null, { taskId: notification.taskId!, status: 'process' }),
                ),
            },
            {
                className: '_grey',
                content: 'Подробнее',
                pageName: 'tasks-inner',
                pageIds: { 2: notification.taskId! },
            },
        ];
    },
};

export default newTask;
