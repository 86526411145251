import React from 'react';

import WidgetField from '@components/crm/widgetField/WidgetField';

import AlertI from '../../types';

const setRejectDealStep = {
    title: 'Выберите причину',
    description: 'Для переноса сделки в этап <b>«Не успешно»</b> необходимо выбрать причину',
    buttonText: 'Выбрать',
    renderOther(this: AlertI) {
        const { model, loadingKey, error, info } = this.state;

        return (
            <>
                <div className="v2popup__field _bottom">
                    <WidgetField
                        model={model}
                        className="_bigSize"
                        name="rejectReasonId"
                        prop="rejectReasonId"
                        type="select"
                        inputSupport="Причина"
                        onChange={async (resultData) => {
                            await this.change(resultData);
                        }}
                        value={model?.groupId}
                        iserror={error?.name === 'rejectReasonId'}
                        cardName="rejectReasonId"
                        disabled={loadingKey === 'save'}
                        selectList="dealRejectReasons"
                        selectProps={{ name: 'rejectReasonName' }}
                        selectText={this.getValue({ key: 'rejectReasonName' })?.value || ''}
                        defaultQuery={[{ key: 'groupId', value: info! }]}
                    />
                </div>
            </>
        );
    },
} as const;

export default setRejectDealStep;
