import React from 'react';

import Widget from '@components/crm/manual/widget/Widget.tsx';
import WidgetWrapper from '@components/crm/manual/widgetWrapper/WidgetWrapper.tsx';
import Table from '@components/crm/table/Table.tsx';

import currentsHandlerCallback from './methods/currentsHandlerCallback.ts';
import getActions from './methods/getActions.ts';
import handlerTableDrop from './methods/handlerTableDrop.ts';
import setInit from './methods/setInit.ts';
import updateHandler from './methods/updateHandler.ts';

import CursorListsItemI from './types.ts';

import lists from './static/lists.ts';

class CursorListsItem
    extends WidgetWrapper<CursorListsItemI['props'], CursorListsItemI['state']>
    implements CursorListsItemI
{
    constructor(props: CursorListsItemI['props']) {
        super(props);
        this.state = {
            showList: false,
        };
    }

    lists = lists;

    getActions = getActions;
    currentsHandlerCallback = currentsHandlerCallback;
    updateHandler = updateHandler;
    handlerTableDrop = handlerTableDrop;
    setInit = setInit;

    componentDidMount(): void {
        const { currents, items } = this.props;

        this.setState({
            currents: currents?.map((id) => ({ id })),
            isInit: true,
            isStatic: !!items,
        });

        this.checkUpdate();
    }

    componentDidUpdate(): void {
        this.checkUpdate();
    }

    render() {
        const {
            name,
            className = '',
            multi,
            withDrop,
            filterName,
            items,
            defaultQuery,
            rowClassName,
            withChoice,
        } = this.props;
        const { currents, isInit, isReady, showList, tableHeight, isStatic } = this.state;
        const list = this.lists[name];
        const { table: tableName } = list;
        const withHead = !isStatic;

        return (
            <>
                <Widget
                    isInit={!!isReady}
                    name={tableName}
                    className={`_white _cursorLists ${className} ${!withHead ? '_withoutHead' : ''}`}
                    
                    filterQuery={(this as CursorListsItemI).getFilterQuery?.()}
                    actions={this.getActions!()}
                    inList={true}
                    updateFilter={(this as CursorListsItemI).updateFilter}
                    searchItems={(this as CursorListsItemI).tableSearchItems}
                    showList={withDrop ? showList : undefined}
                    handlerDrop={this.handlerTableDrop.bind(this)}
                    observerSupportClassName=".v2cursorLists"
                    id={this.id}
                    contentHeight={withDrop && showList ? tableHeight : undefined}
                    withHead={withHead}
                >
                    <Table
                        className={className}
                        name={tableName}
                        isNotLink={true}
                        isInit={!!isInit}
                        inWidget={true}
                        isList={true}
                        withChoice={withChoice}
                        staticItems={items}
                        getQuery={() => [
                            ...(currents && currents.length > 0
                                ? currents.map(({ id: current }) => ({
                                      key: 'currents',
                                      value: current,
                                  }))
                                : []),
                            ...(defaultQuery || []),
                        ]}
                        withHead={false}
                        init={this.tableInit!.bind(this)}
                        callback={this.tableCallback!.bind(this)}
                        currentsHandler={this.currentsHandler.bind(this)}
                        currents={currents}
                        currentsMulti={multi}
                        setInit={this.setInit.bind(this)}
                        localFilterName={filterName}
                        notSaveFilter={true}
                        getRowClassName={rowClassName ? () => rowClassName : undefined}
                    />
                </Widget>
            </>
        );
    }
}

export default CursorListsItem;
