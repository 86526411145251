import React from 'react';

import Link from '@components/link/Link.tsx';
import getFormatedDate from '@functions/getFormatedDate.ts';
import getUserName from '@functions/getUserName.ts';
import CallT from '@global/models/Call.ts';
import SchemaFileT from '@global/schemas/File.ts';
import { CursorActionsItemT } from '@global/types.ts';

import ChatFile from '../../../../../views/crm/chat/components/window/components/file/File.tsx';
import More from '../components/More.tsx';

import { RenderColsT } from '../../types.ts';

const renderUser = function (call: CallT, type: CallT['type']) {
    const LinkTag = call.dealId ? Link : 'div';
    const linkProps = call.dealId
        ? {
              className: 'v2table__text _link',
              pageName: 'recruit-deals-group-inner-main' as const,
              ids: { 3: call.dealGroupId!, 4: call.dealId },
          }
        : { className: 'v2table__text' };

    return call.type === type ? (
        <div className="v2table__text">
            {call.user ? getUserName({ user: call.user, type: 'double' }) : '–'}
        </div>
    ) : call.executor ? (
        <>
            <LinkTag {...linkProps}>{getUserName({ user: call.executor, type: 'double' })}</LinkTag>
            <div className="v2table__text _block _top">{call.executorPhone}</div>
        </>
    ) : (
        <LinkTag {...linkProps}>{call.executorPhone}</LinkTag>
    );
};

const renderCalls: RenderColsT<'calls'> = function ({ name: colName, item }) {
    let content;
    let className;
    const call = item as CallT;

    if (colName === 'type') {
        content = <div className="v2table__text">{call.typeText}</div>;
    }

    if (colName === 'from') {
        content = renderUser(call, 'outcoming');
    }

    if (colName === 'to') {
        content = renderUser(call, 'incoming');
    }

    if (colName === 'status') {
        let statusClassName = '_wait';

        if (call.status === 'completed') {
            statusClassName = '_success';
        }

        if (call.status === 'reject') {
            statusClassName = '_alert';
        }

        content = <div className={`v2table__status ${statusClassName}`}>{call.statusText}</div>;
    }

    if (colName === 'record') {
        className = '_full';

        content =
            call.recordFullSrc && call.recordDuration ? (
                <>
                    <div className="v2table__audio">
                        <ChatFile
                            file={
                                {
                                    _id: call._id,
                                    duration: call.recordDuration!,
                                    path: call.recordSrc!,
                                    fullSrc: call.recordFullSrc,
                                } as SchemaFileT & { fullSrc?: string }
                            }
                        />
                    </div>
                </>
            ) : (
                <>
                    <div className="v2table__text">–</div>
                </>
            );
    }

    if (colName === 'date') {
        const { otherData } = this.props;
        const getMoreActions = otherData?.getMoreActions as (data: {
            call: CallT;
        }) => CursorActionsItemT[];

        content = (
            <>
                <div className="v2table__text">
                    {getFormatedDate({
                        date: new Date(call.createdDate),
                        type: 'full',
                        isShortYear: true,
                    })}
                </div>
                <More
                    id={`call-${call._id}`}
                    items={getMoreActions({ call })}
                    // stopPropagation={true}
                />
            </>
        );
    }

    return { content, className };
};

export default renderCalls;
