import handlerPopup from '@functions/handlerPopup.ts';

import AlertI from '../types.ts';

const close: AlertI['close'] = function (this: AlertI, success) {
    const { popupName } = this.props;

    handlerPopup(popupName || 'alertPopup', {
        isShow: false,
        type: undefined,
        callback: undefined,
        closeCallback: undefined,
        info: undefined,
    });

    if (typeof this.closeCallback === 'function') {
        this.closeCallback(success);
    }
};

export default close;
