import React from 'react';

import CursorSortes from '@components/cursorSortes/CursorSortes.tsx';

import RootI from '../types.ts';

const renderCursorSortes: RootI['renderCursorSortes'] = function () {
    return (
        <>
            <CursorSortes />
        </>
    );
};

export default renderCursorSortes;
