import React from 'react';

import WidgetI from '../types.ts';

import renderAction from './actions/renderAction.tsx';
import renderClose from './actions/renderClose.tsx';
import renderExport from './actions/renderExport.tsx';
import renderFilter from './actions/renderFilter.tsx';
import renderLink from './actions/renderLink.tsx';
import renderSearch from './actions/renderSearch.tsx';
import renderSort from './actions/renderSort.tsx';

const renderHeadAction: WidgetI['renderHeadAction'] = function (props) {
    const { item, nextItem } = props;

    const isLastInGroup = nextItem ? nextItem.group !== item.group : false;

    return (
        <div
            className={`v2widget__headActionsElem _ITEM ${isLastInGroup ? '_lastInGroup' : ''} ${item.className || ''}`}
        >
            <div className="v2widget__headActionsElemInner _COL">
                {item.type === 'export' && renderExport.call(this, props)}
                {item.type === 'filter' && renderFilter.call(this, props)}
                {item.type === 'link' && renderLink.call(this, props)}
                {item.type === 'search' && renderSearch.call(this, props)}
                {item.type === 'close' && renderClose.call(this, props)}
                {item.type === 'action' && renderAction.call(this, props)}
                {item.type === 'sort' && renderSort.call(this, props)}
            </div>
        </div>
    );
};

export default renderHeadAction;
