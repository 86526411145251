type ListT = {
    table: string;
};

const lists = {
    tagsGroups: { table: 'listTagsGroups' },
    tagsGroupsColors: { table: 'listTagsGroupsColors' },
    executors: { table: 'listExecutors' },
    corporations: { table: 'listCorporations' },
    joinScripts: { table: 'listJoinScripts' },
    users: { table: 'listUsers' },
    joinTemplates: { table: 'listJoinTemplates' },
    payProjects: { table: 'listPayProjects' },
    roles: { table: 'listRoles' },
    countries: { table: 'listCountries' },
    passportViews: { table: 'listPassportViews' },
    staticList: { table: 'staticList' },
    countryTypes: { table: 'listCountryTypes' },
    tags: { table: 'listTags' },
    executorTypes: { table: 'listExecutorTypes' },
    executorOrganizations: { table: 'listExecutorOrganizations' },
    cities: { table: 'listCities' },
    docTypes: { table: 'listDocTypes' },
    executorMvdStatuses: { table: 'listExecutorMvdStatuses' },
    integrationNames: { table: 'listIntegrationNames' },
    dealGroups: { table: 'listDealGroups' },
    dealColors: { table: 'listDealColors' },
    dealTriggers: { table: 'listDealTriggers' },
    dealSources: { table: 'listDealSources' },
    dealSteps: { table: 'listDealSteps' },
    dealRejectReasons: { table: 'listDealRejectReasons' },
    taskKeys: { table: 'listTaskKeys' },
    taskTimes: { table: 'listTaskTimes' },
    taskModels: { table: 'listTaskModels' },
    pays: { table: 'listPays' },
    deals: { table: 'listDeals' },
    calls: { table: 'listCalls' },
    articleSystems: { table: 'listArticleSystems' },
    articleCrmBlogSections: { table: 'listArticleCrmBlogSections' },
    articleCrmAnnounceSections: { table: 'listArticleCrmAnnounceSections' },
    articlePublicateTypes: { table: 'listArticlePublicateTypes' },
    dealGroupCustomFieldsTypes: { table: 'listDealGroupCustomFieldsTypes' },
    dealCustomFieldListItems: { table: 'listDealCustomFieldListItems' },
    articleKnowledgeTypes: { table: 'listArticleKnowledgeTypes' },
    articleKnowledgeSections: { table: 'listArticleKnowledgeSections' },
    articles: { table: 'listArticles' },
    integrations: { table: 'listIntegrations' },
    chatCanCreateTypes: { table: 'listChatCanCreateTypes' },
    integrationUtms: { table: 'listIntegrationUtms' },
    cuperLeadTypes: { table: 'listCuperLeadTypes' },
} as const;

export default lists;

export type { ListT };
