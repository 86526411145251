import LazyPageI from '@classes/lazyPage/types.ts';

const loadCrmContent: LazyPageI['loadComponent'] = async function (name) {
    let Component;

    if (
        [
            'content-blog',
            'content-announce',
            'content-legal',
            'content-users-knowledge',
            'content-clients-knowledge',
        ].includes(name)
    ) {
        Component = (await import('../../../../components/crm/modelsPage/ModelsPage')).default;
    }

    return Component;
};

export default loadCrmContent;
