import setAsyncState from '@functions/setAsyncState';
import { CursorSortesT, CustomListenerT, StoreT } from '@global/types';

type StateT = {
    user?: StoreT<'crm'>['user'];
    sortCurrentName?: CursorSortesT['currentName'];
    sortCurrentState?: CursorSortesT['currentState'];
};

interface ThisInterfaceI extends React.Component<{}, StateT> {
    sortName?: string;

    sortEventCallback?: () => void;
    sortEventHandler?: SortEventHandlerT;
    getSortLocalKey?: () => string;
}

type SortEventHandlerT = (
    this: ThisInterfaceI,
    e: CustomEvent<{
        name: string;
        currentName: CursorSortesT['currentName'];
        currentState: CursorSortesT['currentState'];
    }>,
) => Promise<void>;
type GetSortLocalKeysT = (this: ThisInterfaceI) => { current: string; state: string };
type MountHandlerSortT = (this: ThisInterfaceI) => Promise<void>;
type UnmountHandlerSortT = (this: ThisInterfaceI) => Promise<void>;

const sortEventHandler: SortEventHandlerT = async function ({
    detail: { name, currentName, currentState },
}) {
    const { user } = this.state;

    if (name !== this.sortName || !user) {
        return;
    }

    const keys = getSortLocalKeys.call(this);

    if (currentName) {
        localStorage.setItem(keys.current, currentName);
    } else {
        localStorage.removeItem(keys.current);
    }

    if (currentState) {
        localStorage.setItem(keys.state, currentState.toString());
    } else {
        localStorage.removeItem(keys.state);
    }

    await setAsyncState.call(this, {
        sortCurrentName: currentName,
        sortCurrentState: currentState,
    });

    if (this.sortEventCallback) {
        this.sortEventCallback();
    }
};
const getSortLocalKeys: GetSortLocalKeysT = function () {
    const { user } = this.state;
    const corporationId = user?.idOfCurrentCorporation;
    const sortLocalKey = this.getSortLocalKey ? this.getSortLocalKey.call(this) : '';

    return {
        current: `sort-${sortLocalKey}-${this.sortName}-${corporationId}-current`,
        state: `sort-${sortLocalKey}-${this.sortName}-${corporationId}-state`,
    };
};
const sortMountHandler: MountHandlerSortT = async function () {
    const keys = getSortLocalKeys.call(this);

    const sortCurrentName = localStorage.getItem(keys.current);
    const sortCurrentState = localStorage.getItem(keys.state);

    if (sortCurrentName || sortCurrentState) {
        await setAsyncState.call(this, {
            ...(sortCurrentName ? { sortCurrentName } : {}),
            ...(sortCurrentState
                ? { sortCurrentState: +sortCurrentState as CursorSortesT['currentState'] }
                : {}),
        });
    }

    this.sortEventHandler = sortEventHandler.bind(this);

    (document.addEventListener as CustomListenerT)('sort', this.sortEventHandler);
};
const sortUnmountHandler: UnmountHandlerSortT = async function () {
    if (this.sortEventHandler) {
        (document.removeEventListener as CustomListenerT)('sort', this.sortEventHandler);
    }
};

export type { SortEventHandlerT, StateT as SortStateT };
export { sortEventHandler, getSortLocalKeys, sortMountHandler, sortUnmountHandler };
