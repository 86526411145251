const calls = {
    colsOrder: ['type', 'from', 'to', 'status', 'record', 'date'],
    cols: {
        type: {
            content: 'Тип',
            width: 14,
        },
        from: {
            content: 'Кто',
            width: 20,
        },
        to: {
            content: 'Кому',
            width: 20,
        },
        status: {
            content: 'Статус',
            width: 13,
        },
        record: {
            content: 'Запись',
            width: 16,
        },
        date: {
            content: 'Дата',
            width: 17,
        },
    },
    deletesCols: [],
    // rowClassName: '_colCenter',
    url: 'call',
    emptyInfo: {
        title: 'Звонков пока нет',
        description: 'Они будут отображаться здесь',
    },
} as const;

export default calls;
