import React from 'react';

import Checkbox from '@components/checkbox/Checkbox';

import AlertI from '../../types';

const deleteDragDeal = {
    title: 'Подтвердите удаление сделки',
    description(this: AlertI) {
        const { info } = this.state;

        return `Данное действие нельзя отменить. Вы уверены, что хотите удалить <b>сделку&nbsp;${info}</b>?`;
    },
    renderAfter(this: AlertI) {
        const { model } = this.state;

        return (
            <>
                <div className="v2popup__checkbox _top">
                    <Checkbox
                        name="skip"
                        onChange={() => {
                            this.change({ skip: !model?.skip });
                        }}
                        className="_lightWeight"
                        value={!!model?.skip}
                    >
                        Больше не спрашивать
                    </Checkbox>
                </div>
            </>
        );
    },
    buttonText: 'Удалить сделку',
} as const;

export default deleteDragDeal;
