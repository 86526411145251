import React from 'react';
import { connect } from 'react-redux';

import EditBlock from '@components/editBlock/EditBlock.tsx';
import { StoreT } from '@global/types.ts';

import checkChangeType from './methods/checkChangeType.ts';
import close from './methods/close.ts';
import popupInit from './methods/popupInit.ts';
import save from './methods/save.ts';
import textMessageHandler from './methods/textMessageHandler.ts';

import AlertI from './types.ts';

import renderContent from './renders/renderContent.tsx';
import renderDesktop from './renders/renderDesktop.tsx';
import renderFoot from './renders/renderFoot.tsx';
import renderMobile from './renders/renderMobile.tsx';
import contents from './static/contents.ts';

class AlertPopup extends EditBlock<AlertI['props'], AlertI['state']> implements AlertI {
    callback: AlertI['callback'];
    successCallback: AlertI['successCallback'];
    closeCallback: AlertI['closeCallback'];

    constructor(props: AlertI['props']) {
        super(props);
        this.state = {
            data: {},
        };
    }

    contents = contents;

    save = save;
    close = close;

    popupInit = popupInit;
    checkChangeType = checkChangeType;
    textMessageHandler = textMessageHandler;

    renderDesktop = renderDesktop;
    renderMobile = renderMobile;
    renderContent = renderContent;
    renderFoot = renderFoot;

    componentDidMount() {
        this.checkChangeType();
    }

    componentDidUpdate() {
        this.checkChangeType();
    }

    render() {
        return (
            <>
                {this.renderDesktop()}
                {this.renderMobile()}
            </>
        );
    }
}

function mapStateToProps(state: StoreT) {
    return {
        windowHeight: state.windowHeight,
    };
}

export default connect(mapStateToProps)(AlertPopup);
