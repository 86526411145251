import React from 'react';

import Cookie from '@components/Cookie.jsx';
import ServerNotAvailable from '@components/ServerNotAvailable.jsx';
import Animate from '@components/animate/Animate.tsx';
import PopupWrapper from '@components/popupWrapper/PopupWrapper.tsx';
import Alert from '@components/popups/alert/Alert.tsx';
import NewYear from '@components/popups/newYear/NewYear.tsx';
import getCurrentPage from '@functions/getCurrentPage.ts';

import I from '../types.ts';

const renderPopups: I['renderPopups'] = function () {
    const {
        storePages,
        logsPopup,
        chatTemplatesPopup,
        paySignPopup,
        contractSignPopup,
        payPopup,
        joinContractPopup,
        payGroupUpload,
        userPopup,
        corporationEditPopup,
        corporationAmountDocPopup,
        corporationCreatePopup,
        roleSectionPopup,
        rolePopup,
        docPopup,
        walletPopup,
        executorCreatePopup,
        executorMvdPopup,
        executorLimitsPopup,
        chatEditPopup,
        galeryPopup,
        galeryPin,
        newYearPopup,
        designMode,
        chatTemplatePopup,
        chatMailingPopup,
        exportPopup,
        chatExecutorDocPopup,
        payGroupPopup,
        integrationPopup,
        beelineUserPopup,
        dealGroupPopup,
        dealTriggerPopup,
        taskPopup,
        legalNavPopup,
        callPopup,
        newVersionPopup,
        dealPopup,
        dealGroupCustomFieldsPopup,
        knowledgeSettingsPopup,
        chatsPopup,
        dealCallPopup,
        dealsImportPopup,
        newChatPopup,
        integrationUtmPopup,
    } = this.props;

    const currentPageName = getCurrentPage({
        storePages,
        filter: (page) => !page.level,
    });

    if (currentPageName === 'pdf') {
        return null;
    }

    return (
        <>
            <Cookie />

            <ServerNotAvailable isHide={currentPageName === 'public'} />
            <PopupWrapper name="chats" className="_chats" isShow={!!chatsPopup?.isShow} />
            <PopupWrapper name="logs" isShow={!!logsPopup?.isShow} />
            <PopupWrapper name="chatTemplates" isShow={!!chatTemplatesPopup?.isShow} />
            <PopupWrapper name="paySign" isShow={!!paySignPopup?.isShow} />
            <PopupWrapper name="contractSign" isShow={!!contractSignPopup?.isShow} />
            <PopupWrapper name="payEdit" isShow={!!payPopup?.isShow} />
            <PopupWrapper name="contractEdit" isShow={!!joinContractPopup?.isShow} />
            <PopupWrapper name="payGroupUpload" isShow={!!payGroupUpload?.isShow} />
            <PopupWrapper name="user" isShow={!!userPopup?.isShow} />
            <PopupWrapper name="corporationEdit" isShow={!!corporationEditPopup?.isShow} />
            <PopupWrapper
                name="corporationAmountDoc"
                isShow={!!corporationAmountDocPopup?.isShow}
            />
            <PopupWrapper name="corporationCreate" isShow={!!corporationCreatePopup?.isShow} />
            <PopupWrapper name="corporationRoleSection" isShow={!!roleSectionPopup?.isShow} />
            <PopupWrapper name="role" isShow={!!rolePopup?.isShow} />
            <PopupWrapper name="doc" isShow={!!docPopup?.isShow} />
            <PopupWrapper name="wallet" isShow={!!walletPopup?.isShow} />
            <PopupWrapper name="executorCreate" isShow={!!executorCreatePopup?.isShow} />
            <PopupWrapper name="executorMvd" isShow={!!executorMvdPopup?.isShow} />
            <PopupWrapper name="executorLimits" isShow={!!executorLimitsPopup?.isShow} />
            <PopupWrapper name="chatEdit" isShow={!!chatEditPopup?.isShow} />
            <PopupWrapper name="chatTemplate" isShow={!!chatTemplatePopup?.isShow} />
            <PopupWrapper name="chatMailing" isShow={!!chatMailingPopup?.isShow} />
            <PopupWrapper name="export" isShow={!!exportPopup?.isShow} />
            <PopupWrapper name="chatExecutorDoc" isShow={!!chatExecutorDocPopup?.isShow} />
            <PopupWrapper name="payGroup" isShow={!!payGroupPopup?.isShow} />
            <PopupWrapper name="integration" isShow={!!integrationPopup?.isShow} />
            <PopupWrapper name="beelineUser" isShow={!!beelineUserPopup?.isShow} />
            <PopupWrapper name="dealGroup" isShow={!!dealGroupPopup?.isShow} />
            <PopupWrapper name="dealTrigger" isShow={!!dealTriggerPopup?.isShow} />
            <PopupWrapper name="task" isShow={!!taskPopup?.isShow} />
            <PopupWrapper name="legalNav" isShow={!!legalNavPopup?.isShow} />
            <PopupWrapper name="call" isShow={!!callPopup?.isShow} />
            <PopupWrapper name="deal" isShow={!!dealPopup?.isShow} />
            <PopupWrapper name="dealCall" isShow={!!dealCallPopup?.isShow} />
            <PopupWrapper name="dealsImport" isShow={!!dealsImportPopup?.isShow} />
            <PopupWrapper name="newChat" isShow={!!newChatPopup?.isShow} />
            <PopupWrapper name="integrationUtm" isShow={!!integrationUtmPopup?.isShow} />

            <PopupWrapper
                name="dealGroupCustomFields"
                isShow={!!dealGroupCustomFieldsPopup?.isShow}
            />
            <PopupWrapper name="knowledge" isShow={!!knowledgeSettingsPopup?.isShow} />

            <PopupWrapper name="galery" isShow={!!galeryPopup?.isShow && !galeryPin} />
            <PopupWrapper
                name="galery"
                isShow={!!galeryPopup?.isShow && galeryPin}
                className="_pin"
                props={{ pin: true }}
            />
            {designMode === 'newYear' && (
                <Animate
                    className={`body__v2popup _POPUPWRAPPER _POPUPBACK _newYear`}
                    isShow={!!newYearPopup?.isShow}
                >
                    <NewYear />
                </Animate>
            )}

            <Animate
                className={`body__v2popup _POPUPWRAPPER _POPUPBACK _newVersion`}
                isShow={newVersionPopup.isShow}
            >
                <div className={`body__v2popupInner _show`}>
                    <Alert
                        alertPopup={{ ...newVersionPopup, type: 'newVersion' }}
                        popupName="newVersionPopup"
                    />
                </div>
            </Animate>
        </>
    );
};

export default renderPopups;
