import React from 'react';

import Avatar from '@components/avatar/Avatar.tsx';
import IntegrationUtmT from '@global/models/IntegrationUtm.ts';

import { RenderColsT } from '../../types.ts';

const renderListIntegrationUtms: RenderColsT<'listIntegrationUtms'> = function ({
    name: colName,
    item,
}) {
    const integrationUtm = item as Pick<IntegrationUtmT, '_id' | 'content' | 'description'> & {
        integrationDescription?: string;
        integrationLogoSrc?: string;
    };
    let content;
    let className;

    if (colName === 'name') {
        className = '_full';
        content = (
            <>
                <div className={`v2table__preview _ROW _hiddenAvatar _mediumSize _notBack`}>
                    <div className="v2table__previewAvatar">
                        <Avatar className="_notRad" image={integrationUtm.integrationLogoSrc} />
                    </div>
                    <div className="v2table__previewInfo">
                        <div className="v2table__previewInfoInner">
                            <div className="v2table__text _block">
                                {integrationUtm.integrationDescription}
                            </div>
                            <div className="v2table__text _grey">{integrationUtm.description}</div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return { content, className };
};

export default renderListIntegrationUtms;
