import I from '../types.ts';

const sortItems: I['sortItems'] = function (items) {
    const { sortCurrentName, sortCurrentState } = this.state;
    const sortProp = sortCurrentName === 'important' ? 'sortKey' : 'updatedDateTime';
    const startItems = [...items].sort((a, b) => b.updatedDateTime! - a.updatedDateTime!);

    const resultItems = startItems.sort((a, b) =>
        sortCurrentState === 1 ? a[sortProp]! - b[sortProp]! : b[sortProp]! - a[sortProp]!,
    );

    if (sortCurrentName !== 'important') {
        resultItems.sort((a, b) => b.sortKey - a.sortKey);
    }

    return resultItems;
};

export default sortItems;
