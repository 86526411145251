import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import changePage from '../../functions/changePage';
import getPageLink from '../../functions/getPageLink';

import Animate from '../Animate.jsx';
import Icon from '../icon/Icon.tsx';

class SupportChatBtn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { user } = this.props;
        const hasAdmin = user?.corporations.find((item) => item.id === 'admin');

        return (
            <Animate
                className="body__supportChatBtn _col _click"
                isShow={user && user.corporations.length > 0 && user.supportChatId && !hasAdmin}
                onClick={() => {
                    changePage({
                        href: getPageLink({ name: 'chat-inner', ids: { 2: user?.supportChatId } }),
                    });
                }}
            >
                <i className="body__supportChatBtnIcon">
                    <Icon name="chat" />
                </i>
                <div className="body__supportChatBtnText">Чат</div>
            </Animate>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(SupportChatBtn);

SupportChatBtn.propTypes = {
    user: PropTypes.object,
};
