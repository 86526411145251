const rejectTask = {
    title: 'На доработку',
    description: `Введите причину доработки задачи`,
    buttonText: 'Отправить на доработку',
    textMessage: {
        support: 'Причина',
    },
} as const;

export default rejectTask;
