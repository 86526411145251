import setAsyncTimer from '@functions/setAsyncTimer.ts';
import NotificationT from '@global/models/Notification.ts';
import getListItems from '@requests/getListItems.ts';

import I from '../types.ts';

const getItems: I['getItems'] = async function (this: I) {
    const { sortCurrentName, sortCurrentState } = this.state;
    const query = [...this.getQueryForRequest().params];

    if (sortCurrentName && sortCurrentState) {
        query.push({ key: 'sortKey', value: sortCurrentName });
        query.push({ key: 'sortValue', value: sortCurrentState.toString() });
    }

    try {
        if (0) {
            await setAsyncTimer(1_000);
        }

        const { items, filter, isLimit, counter } = await getListItems<NotificationT>({
            url: 'notifications',
            query,
        });

        await this.setItems({
            items,
            filter,
            counter,
            isLimit,
            itemsSet: (newItems: { _id: string }[]) => this.sortItems(newItems as NotificationT[]),
        });

        return { items };
    } catch (error) {
        return Promise.reject();
    }
};

export default getItems;
