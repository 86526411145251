import AlertI from '../../types';

const changeBeelineToken = {
    title: 'Внимание!',
    description(this: AlertI) {
        return 'При изменении токена интеграции все привязанные аккаунты <b>будут удалены</b>. Уверены, что хотите изменить токен?';
    },
    buttonText: 'Отменить',
    cancelText: 'Всё равно сохранить',
    mode: 'important',
} as const;

export default changeBeelineToken;
