import React from 'react';

import Icon from '@components/icon/Icon';
import Link from '@components/link/Link';
import List from '@components/list/List';
import changeCorporation from '@functions/crm/changeCorporation';
import getPermissions from '@functions/crm/getPermissions';
import { StoreT } from '@global/types';

import SideBarI from '../types';

type ParamsT = {
    user?: StoreT<'crm'>['user'];
    corporation: ReturnType<SideBarI['getCorporation']>;
    notAdminCorporation: string | undefined;
    saveUser: SideBarI['saveUser'];
    companyListHandler: SideBarI['companyListHandler'];
};

const renderButton = function (props: ParamsT, { item }: { item: ItemT }) {
    if (item._id === 'settings') {
        return (
            <Link className={`v2sideBar__headSettingsButton _ROW`} pageName="settings">
                <i className="v2sideBar__headSettingsButtonIcon _settings">
                    <Icon name="sideBar-settings" />
                </i>
                Настройки
            </Link>
        );
    }

    if (item._id === 'clients-knowledge') {
        return (
            <Link
                className={`v2sideBar__headSettingsButton _ROW`}
                pageName="clients-knowledge"
            >
                <i className="v2sideBar__headSettingsButtonIcon _settings">
                    <Icon name="sideBar-content" />
                </i>
                База знаний
            </Link>
        );
    }

    if (item._id === 'users-knowledge') {
        return (
            <Link className={`v2sideBar__headSettingsButton _ROW`} pageName="users-knowledge">
                <i className="v2sideBar__headSettingsButtonIcon _settings">
                    <Icon name="sideBar-content" />
                </i>
                База знаний
            </Link>
        );
    }

    const { user, saveUser, companyListHandler } = props;

    if (item._id === 'admin') {
        return (
            <div
                className={`v2sideBar__headSettingsButton _ROW _CLICK _admin`}
                onClick={async () => {
                    await changeCorporation({ user, id: 'admin' });

                    companyListHandler(false);

                    saveUser();
                }}
            >
                <i className="v2sideBar__headSettingsButtonIcon _admin">
                    <Icon name="admin" />
                </i>
                Админ панель
                <i className="v2sideBar__headSettingsButtonIcon _nextArrow">
                    <Icon name="arrow-next-2" />
                </i>
            </div>
        );
    }

    return (
        <div
            className={`v2sideBar__headSettingsButton _ROW _CLICK`}
            onClick={async () => {
                await changeCorporation({ user, id: item?.corporationId });

                companyListHandler(false);

                saveUser();
            }}
        >
            <i className="v2sideBar__headSettingsButtonIcon _prevArrow">
                <Icon name="arrow-prev-2" />
            </i>
            Вернуться в {item?.corporationName}
        </div>
    );
};

type ItemT = { _id: string; corporationId?: string; corporationName?: string };

const getMainButtons = function ({ user, corporation }: ParamsT): ItemT[] {
    const items: ItemT[] = [];

    if (getPermissions(user, { key: 'settings' })) {
        items.push({ _id: 'settings' });
    }

    if (corporation?.isAdmin) {
        items.push({ _id: 'users-knowledge' });
    } else {
        items.push({ _id: 'clients-knowledge' });
    }

    return items;
};

const getCorporationButtons = function ({
    user,
    corporation,
    notAdminCorporation,
}: ParamsT): ItemT[] {
    const items: ItemT[] = [];

    if (!corporation?.isAdmin) {
        if (user?.corporations.find((item) => item.id === 'admin')) {
            items.push({ _id: 'admin' });
        }
    } else if (
        user?.corporations.filter((item) => item.id !== 'admin').length &&
        notAdminCorporation
    ) {
        const thisCorporation = user.corporationsInfo.find(
            (item) => item._id === notAdminCorporation,
        );

        items.push({
            corporationId: notAdminCorporation,
            _id: `corporations-${notAdminCorporation}`,
            corporationName: thisCorporation?.shortName || thisCorporation?.fullName,
        });
    }

    return items;
};

export default function Settings({ ...props }: ParamsT): React.ReactNode {
    const mainButtons = getMainButtons({ ...props });
    const corporationButtons = getCorporationButtons({ ...props });

    return (
        <div className="v2sideBar__headSettings">
            <div className="v2sideBar__headSettingsInner _COL">
                <List
                    renderKey={mainButtons.map((item) => item._id).join('')}
                    items={mainButtons}
                    parentClass="v2sideBar__headSettingsButtons"
                    itemClass="v2sideBar__headSettingsButtonsItem"
                    itemStyleProps={['left']}
                    parentStyleProps={['height']}
                    renderItem={renderButton.bind(null, props)}
                    // testMode={true}
                />
                <List
                    renderKey={corporationButtons.map((item) => item._id).join('')}
                    items={corporationButtons}
                    parentClass="v2sideBar__headSettingsButtons _corporation"
                    itemClass="v2sideBar__headSettingsButtonsItem"
                    itemStyleProps={['left']}
                    parentStyleProps={[]}
                    renderItem={renderButton.bind(null, props)}
                />
            </div>
        </div>
    );
}
