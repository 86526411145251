import { WidgetActionsT } from '@components/crm/manual/widget/types.ts';

import I from '../types.ts';

const getActions: I['getActions'] = function (this: I) {
    const { name, inFilter } = this.props;
    const list = this.lists[name];
    const { table: tableName } = list;

    const actions: WidgetActionsT[] = [];

    if (this.getFilter) {
        const filter = this.getFilter();
        const filterSearch = filter?.find((item) => item.main);
        const withFilter = !!(filter && filter.filter((item) => !item.main).length > 0);

        if (filter) {
            actions.push({
                type: 'search',
                name: 'search',
                filterProp: filterSearch?.name,
                group: 'search',
                content: filterSearch?.inputSupport || 'Поиск',
                className: !withFilter || inFilter ? '_full' : '',
            });
        }

        if (withFilter && !inFilter) {
            actions.push({
                type: 'filter',
                name: 'filter',
                group: 'filter',
                filterName: tableName,
            });
        }
    }

    return actions;
};

export default getActions;
