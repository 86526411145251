import I from '../types.ts';

const checkChangeType: I['checkChangeType'] = function () {
    const { alertPopup } = this.props;

    if (alertPopup.type && alertPopup.type !== this.state.type) {
        this.popupInit();
    }
};

export default checkChangeType;
