import React from 'react';

type PropsT = {
    date: string;
};

interface DateI extends React.Component {
    date?: string;
}

export default class DealDate extends React.Component<PropsT> implements DateI {
    date?: string;

    constructor(props: PropsT) {
        super(props);
        this.state = {};

        this.date = this.props.date;
    }

    render() {
        return <div className="v2table__text _grey">{this.date}</div>;
    }
}
