import setAsyncState from '@functions/setAsyncState.ts';

import I from '../types.ts';

const popupInit: I['popupInit'] = async function () {
    const { alertPopup } = this.props;
    const { type, callback, successCallback, closeCallback, textMessage, ...props } = alertPopup;

    if (typeof callback === 'function') {
        this.callback = callback;
    }

    if (typeof successCallback === 'function') {
        this.successCallback = successCallback;
    }

    if (typeof closeCallback === 'function') {
        this.closeCallback = closeCallback;
    }

    await setAsyncState.call(this, { type, ...(props as any), textMessage });

    await this.init({ fields: {} });
};

export default popupInit;
