import React from 'react';

import AnimateChange from '@components/animateChange/AnimateChange.tsx';

import WidgetI from '../types.ts';

const renderHead: WidgetI['renderHead'] = function () {
    const {
        title,
        description = '',
        descriptionInner,
        inWidget,
        renderHeadTitle,
        renderHeadTitleOther,
        showList,
        withHead = true,
        backPageName,
    } = this.props;

    if (withHead === false) {
        return null;
    }

    const classes: string[] = [
        'v2widget__head _ROW',
        ...(backPageName ? ['_withBack'] : []),
        ...(typeof showList === 'boolean' ? ['_withDrop'] : []),
    ];

    if (this.props.renderHead) {
        return (
            <div className={classes.join('')}>
                {this.renderBack()}
                {this.props.renderHead()}
            </div>
        );
    }

    return (
        <div className={classes.join(' ')}>
            {this.renderBack()}
            <div className="v2widget__headInfo _ROW">
                {renderHeadTitle ? (
                    <>{renderHeadTitle()}</>
                ) : (
                    <>
                        {title && (
                            <>
                                <div className="v2widget__headName _ROW">
                                    {title}:
                                    <div className="v2widget__headDescription">
                                        {description}{' '}
                                        {(typeof descriptionInner === 'number' ||
                                            typeof descriptionInner === 'string') && (
                                            <>
                                                <AnimateChange
                                                    renderKey={descriptionInner}
                                                    className={`v2widget__headDescriptionInner ${inWidget ? '_inWidget' : ''}`}
                                                >
                                                    {typeof descriptionInner === 'string' ? (
                                                        <>{descriptionInner}</>
                                                    ) : (
                                                        <>({descriptionInner})</>
                                                    )}
                                                </AnimateChange>
                                            </>
                                        )}
                                    </div>
                                </div>
                                {renderHeadTitleOther && renderHeadTitleOther()}
                            </>
                        )}
                    </>
                )}
            </div>
            {this.renderHeadActions()}
        </div>
    );
};

export default renderHead;
