import React from 'react';

import Icon from '@components/icon/Icon.tsx';
import List from '@components/list/List.tsx';
import handlerPopup from '@functions/handlerPopup.ts';
import { setCursorSupport } from '@functions/supportHandler.ts';
import { FilterQueryT } from '@global/types.ts';

import getCounter from './methods/getCounter.ts';

import FilterButtonI from './types.ts';

class FilterButton
    extends React.Component<FilterButtonI['props'], FilterButtonI['state']>
    implements FilterButtonI
{
    parent: FilterButtonI['parent'];

    constructor(props: FilterButtonI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    getCounter = getCounter;

    render() {
        const { className = '', filter, query, name, setFilter, localName } = this.props;
        const supportProps = setCursorSupport(
            { _id: `filter-${name}`, content: 'Фильтр', dir: 'bottom' },
            { targetClassName: '.filterButton' },
        );
        const counter = this.getCounter();

        return (
            <div
                ref={this.parent}
                className={`filterButton _COL _CLICK ${className}`}
                {...supportProps}
                onClick={() => {
                    handlerPopup('filterPopup', {
                        isShow: true,
                        filter,
                        filterQuery: query,
                        name,
                        callback: (data: FilterQueryT[]) => {
                            setFilter(data);

                            if (localName) {
                                localStorage.setItem(localName, JSON.stringify(data));
                            }
                        },
                    });
                }}
            >
                <div className="filterButton__icon">
                    <Icon name="widget-filter" />
                </div>
                <List
                    renderKey={counter > 0 ? counter.toString() : undefined}
                    items={counter > 0 ? [{ _id: counter.toString() }] : []}
                    parentClass="filterButton__counterWrapper _fullWidth _fullHeight"
                    itemStyleProps={[]}
                    parentStyleProps={['height']}
                    changeAnimate={true}
                    renderItem={({ item }) => (
                        <div className="filterButton__counter _COL">{item._id}</div>
                    )}
                />
            </div>
        );
    }
}

export default FilterButton;
