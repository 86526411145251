import { NotificationInfoT } from '../../types';

const rejectTask: NotificationInfoT = {
    subTitle: 'Задачи',
    title() {
        const { notification } = this.props;

        return `Задача №${notification.taskNumber} на доработке`;
    },
    description() {
        const { notification } = this.props;

        return `Причина: ${notification.taskRejectReason}`;
    },
    buttons() {
        const { notification } = this.props;

        return [
            {
                className: '_grey',
                content: 'Подробнее',
                pageName: 'tasks-inner',
                pageIds: { 2: notification.taskId! },
            },
        ];
    },
};

export default rejectTask;
