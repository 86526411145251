import React from 'react';

import checkUpdate from './methods/checkUpdate.ts';
import currentsHandler from './methods/currentsHandler.ts';
import getModel from './methods/getModel.ts';
import setModel from './methods/setModel.ts';
import tableCallback from './methods/tableCallback.ts';
import tableInit from './methods/tableInit.ts';
import wrapperMountHandler from './methods/wrapperMountHandler.ts';
import wrapperUnmountHandler from './methods/wrapperUnmountHandler.ts';

import WidgetWrapperI from './types.ts';

class WidgetWrapper<P = {}, S = {}>
    extends React.Component<WidgetWrapperI<P, S>['props'], WidgetWrapperI<P, S>['state']>
    implements WidgetWrapperI
{
    parent: WidgetWrapperI['parent'];
    id: WidgetWrapperI['id'];

    constructor(props: WidgetWrapperI<P, S>['props']) {
        super(props);
        this.state = {} as WidgetWrapperI<P, S>['state'];

        this.id = `${new Date().getTime()}${Math.round(Math.random() * Math.random() * 1_000)}`;

        this.parent = React.createRef();
    }

    getModel = getModel;
    setModel = setModel;
    checkUpdate = checkUpdate;

    tableInit = tableInit;
    tableCallback = tableCallback;

    currentsHandler = currentsHandler;

    wrapperMountHandler = wrapperMountHandler;
    wrapperUnmountHandler = wrapperUnmountHandler;

    componentDidMount() {
        this.wrapperMountHandler();
    }

    componentWillUnmount() {
        this.wrapperUnmountHandler();
    }
}

export default WidgetWrapper;
