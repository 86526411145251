import handlerPopup from '@functions/handlerPopup';

import { dispatcher, store } from '../../redux/redux';
import changePage from '../changePage';
import getPageLink from '../getPageLink';

export default async function changeCorporation({ user = store.getState().user, id }) {
    await dispatcher({
        type: 'user',
        data: { ...user, idOfCurrentCorporation: id },
    });

    await changePage({
        href: getPageLink({ name: 'corporation', ids: { 0: id } }),
    });

    await dispatcher({ type: 'notReadChatCount', value: 0 });

    handlerPopup('chatsPopup', { isShow: false });

    setTimeout(() => {
        handlerPopup('chatsPopup', { isShow: false, id: undefined });
    }, 500);

    document.dispatchEvent(new CustomEvent('changeCorporation', { detail: { id } }));

    document.dispatchEvent(new CustomEvent('changeUser', { detail: {} }));
}
