import React from 'react';

import Icon from '@components/icon/Icon.tsx';
import LoaderBlock from '@components/loaderBlock/LoaderBlock.tsx';

import CursorActionsI from '../types.ts';

const renderItem: CursorActionsI['renderItem'] = function ({ item }) {
    const { loadingKey } = this.state;

    return (
        <div
            className={`v2cursorSortes__item _${item.dir}`}
            style={
                item.position && {
                    left: `${item.position.left}px`,
                    top: `${item.position.top}px`,
                }
            }
        >
            {item.items.map((action) => {
                const { name, text } = action;
                const isCurrent = item.currentName === name;

                return (
                    <div
                        className={`v2cursorSortes__itemAction _CLICK`}
                        key={name}
                        onClick={async () => {
                            await this.itemHandler({ id: item._id, item: action });
                        }}
                    >
                        <div className="v2cursorSortes__itemActionInner">
                            <LoaderBlock
                                className="v2cursorSortes__itemActionLoader"
                                isShow={loadingKey === action.name && false}
                            />
                            {text}
                            <div
                                className={`v2cursorSortes__itemActionBox ${isCurrent ? '_active' : ''}`}
                            >
                                <i
                                    className={`v2cursorSortes__itemActionIcon ${isCurrent && item.currentState === 1 ? '_current' : ''}`}
                                >
                                    <Icon name="sort-top" />
                                </i>
                                <i
                                    className={`v2cursorSortes__itemActionIcon ${isCurrent && item.currentState === -1 ? '_current' : ''}`}
                                >
                                    <Icon name="sort-down" />
                                </i>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default renderItem;
