import { CursorSortesT } from '@global/types.ts';
import { dispatcher, store } from '@redux/redux.ts';

import CursorActionsI from '../types.ts';

const itemHandler: CursorActionsI['itemHandler'] = async function ({ id, item }) {
    const cursorSortes = [...store.getState().cursorSortes];
    const cursorSortesAction = cursorSortes.find((innerItem) => innerItem._id === id);

    if (!cursorSortesAction) {
        return;
    }

    const cursorSortesItem = cursorSortesAction.items.find(
        (innerItem) => innerItem.name === item.name,
    );

    if (!cursorSortesItem) {
        return;
    }

    let resultState =
        cursorSortesAction.currentState === undefined
            ? 1
            : cursorSortesAction.currentState === 1
              ? -1
              : undefined;

    if (item.name !== cursorSortesAction.currentName) {
        resultState = 1;
    }

    const resultName = resultState === undefined ? undefined : item.name;

    // if (resultName === undefined && cursorSortesAction.defaultName) {
    //     resultName = cursorSortesAction.defaultName;
    //     resultState = cursorSortesAction.defaultState;
    // }

    cursorSortesAction.currentState = resultState as CursorSortesT['currentState'];
    cursorSortesAction.currentName = resultName;

    await dispatcher({ type: 'cursorSortes', data: cursorSortes });

    document.dispatchEvent(
        new CustomEvent('sort', {
            detail: {
                name: cursorSortesAction.name,
                currentName: cursorSortesAction.currentName,
                currentState: cursorSortesAction.currentState,
            },
        }),
    );

    // const cursorActions = store.getState().cursorActions;
    // const cursorAction = cursorActions
    //     .find((innerItem) => innerItem._id === item._id)
    //     ?.items.find((innerAction) => innerAction.name === action.name);

    // await handlerLoading.call(this, action.name);

    // try {
    //     await cursorAction?.onClick();

    //     deleteCursorActions({ id: item._id });
    // } catch (error) {}

    // await handlerLoading.call(this, undefined);
};

export default itemHandler;
